<form >
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{'bookings_page.reductions.title' |translate}}</h2>

    <!--<button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>-->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">

    <div class="button-div" (click)="type = 1" [ngStyle]="{'background': type === 1 ? '#FF0055' : '#fff', 'color': type === 1 ? '#fff' : 'black'}">{{'bookings_page.reductions.percentaje' | translate}}</div>
    <div class="button-div" (click)="type = 2" [ngStyle]="{'background': type === 2 ? '#FF0055' : '#fff', 'color': type === 2 ? '#fff' : 'black'}">{{'bookings_page.reductions.amount' | translate}}</div>

    <div style="width:100%" [formGroup]="form">
      <mat-form-field appearance="outline" style="width:100%">
        <mat-label>{{'bookings_page.reductions.discount' |translate}}</mat-label>
        <input type="number" matInput formControlName="discount" [(ngModel)]="discount"/>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button color="primary" mat-flat-button [mat-dialog-close]="{type: type, discount: discount}" [disabled]="form.invalid">{{'confirm' | translate}}</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="false">{{'no' | translate}}</button>
  </mat-dialog-actions>
</form>
