<mat-card style="box-shadow: none;">
  <mat-card-content style="padding: none">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" [class.highlighted]="selectedClient === 'Client 1'" (click)="selectClient('Client 1')">
          <vex-widget-quick-value-center
              icon="mat:group"
              iconClass="text-primary bg-primary/10"
              label="Client 1"
              [canShare]="false"
              [withPercent]="false">
          </vex-widget-quick-value-center>
      </div>

      <div class="swiper-slide" [class.highlighted]="selectedClient === 'Client 2'" (click)="selectClient('Client 2')">
          <vex-widget-quick-value-center
              icon="mat:group"
              iconClass="text-primary bg-primary/10"
              label="Client 2"
              [canShare]="false"
              [withPercent]="false">
          </vex-widget-quick-value-center>
      </div>

      <div class="swiper-slide" [class.highlighted]="selectedClient === 'Client 3'" (click)="selectClient('Client 3')">
          <vex-widget-quick-value-center
              icon="mat:group"
              iconClass="text-primary bg-primary/10"
              label="Client 3"
              [canShare]="false"
              [withPercent]="false">
          </vex-widget-quick-value-center>
      </div>

      <div class="swiper-slide" [class.highlighted]="selectedClient === 'Client 4'" (click)="selectClient('Client 4')">
          <vex-widget-quick-value-center
              icon="mat:group"
              iconClass="text-primary bg-primary/10"
              label="Client 4"
              [canShare]="false"
              [withPercent]="false">
          </vex-widget-quick-value-center>
      </div>

      <div class="swiper-slide" [class.highlighted]="selectedClient === 'Client 5'" (click)="selectClient('Client 5')">
          <vex-widget-quick-value-center
              icon="mat:group"
              iconClass="text-primary bg-primary/10"
              label="Client 5"
              [canShare]="false"
              [withPercent]="false">
          </vex-widget-quick-value-center>
      </div>

      </div>
      <!-- Flechas de navegación -->
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </mat-card-content>
</mat-card>
