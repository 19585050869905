<mat-card>
  <mat-card-header>
  </mat-card-header>
  <mat-card-content>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <ng-container *ngFor="let sport of data" >
          <div class="swiper-slide" *ngIf="sport.sport_type === type" [class.highlighted]="selectedSport === sport.id" (click)="selectClient(sport.id)">
            <vex-widget-quick-value-center-custom
                [icon]="sport.icon"
                iconClass="text-primary bg-primary/10"
                label="{{sport.name}}"
                [canShare]="false"
                [withPercent]="false">
            </vex-widget-quick-value-center-custom>
          </div>
        </ng-container>
      </div>
      <!-- Flechas de navegación -->
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </mat-card-content>
</mat-card>
