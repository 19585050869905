<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <title>{{ subject }}</title>

  <!-- CSS Reset -->
  <style>
    /* What it does: Remove spaces around the email design added by some email clients. */
    /* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
    html,body { margin: 0 auto !important; padding: 0 !important; height: 100% !important; width: 100% !important; }
    /* What it does: Stops email clients resizing small text. */
    * { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;  }/* What is does: Centers email on Android 4.4 */
    div[style*="margin: 16px 0"] { margin:0 !important; }
    /* What it does: Stops Outlook from adding extra spacing to tables. */
    table, td { mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important;}
    /* What it does: Fixes webkit padding issue. Fix for Yahoo mail table alignment bug. Applies table-layout to the first 2 tables then removes for anything nested deeper. */
    table { border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important;}
    table table table { table-layout: auto; }
    /* What it does: Uses a better rendering method when resizing images in IE. */
    img { -ms-interpolation-mode:bicubic;}
    /* What it does: A work-around for iOS meddling in triggered links. */
    .mobile-link--footer a, a[x-apple-data-detectors] { color:inherit !important; text-decoration: underline !important;}
  </style>

  <style>
    /* Media Queries */
    .borde{border-right:1px solid #152a69;}
    @media screen and (max-width: 500px) { .borde-email{ border-left: 10px solid #dcdcdd !important; 	border-right: 10px solid #dcdcdd !important;	 }
      .email-container { width: 100% !important; margin: auto !important; }
      /* What it does: Forces elements to resize to the full width of their container. Useful for resizing images beyond their max-width. */
      .fluid { max-width: 100% !important; height: auto !important; margin-left: auto !important; margin-right: auto !important; }
      /* What it does: Forces table cells into full-width rows. */
      .stack-column,
      .stack-column-center { display: block !important; width: 100% !important; max-width: 100% !important; direction: ltr !important; }
      .stack-column-left{ display: block !important; width: 100% !important; max-width: 100% !important; direction: ltr !important; align: left;}
      /* And center justify these ones. */
      .stack-column-center .left { text-align: left !important; }
      /* What it does: Generic utility class for centering. Useful for images, buttons, and nested tables. */
      .center-on-narrow { text-align: center !important; display: block !important; margin-left: auto !important; margin-right: auto !important; float: none !important; border-left: 0px!important; padding-left: 10px!important; padding-right: 10px!important;}
      .left-on-narrow { text-align: left !important; display: block !important; margin-left: auto !important; margin-right: auto !important; float: none !important; }
      .right-on-narrow { text-align: right !important; display: block !important; margin-left: auto !important; margin-right: auto !important; float: none !important; }
      table.center-on-narrow { display: inline-block !important; }
      .onSmall {display:block !important;}
      .hideSmall {display:none !important;}
      .centerMovil { text-align:center !important; }
      .altura{ height: auto!important; padding-bottom: 10px!important; }
      .saltolinea{display: none; mso-line-height-rule: exactly;}
      .show{display:none; mso-hide: all;}
      .f35mov, .f35mov font{font-size:35px !important; line-height:39px !important;}
      .hide{ display: block !important; }
      .borde{border-right:0px solid #152a69 !important;}
      .textcentermv{text-align:center !important; align: center;}
      .bordemv{border-bottom:2px solid #f4f4f4;}
      .movcenter, .movcenter font{text-align:center !important;}
      .pad20mov{padding-left: 20px !important; padding-right:20px !important;}
    }
  </style>

  <!--[if gte mso 9]><xml>
  <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
  </o:OfficeDocumentSettings>
</xml><![endif]-->

  <!--[if gte mso 9]>
  <style type="text/css">
    * { -webkit-font-smoothing: antialiased; }
    body { Margin: 0; padding: 0; min-width: 100%; background-color: #dbdbdb; font-family: Arial, sans-serif; -webkit-font-smoothing: antialiased; }
    table { border-spacing: 0; color: #333333; font-family: Arial, sans-serif; }
    img { border: 0; }
    .h1 { font-size: 21px; font-weight: bold; Margin-top: 15px; Margin-bottom: 5px; font-family: Arial, sans-serif; -webkit-font-smoothing: antialiased; }
  </style>
  <![endif]-->
</head>
<body bgcolor="#ffffff" width="100%" style="margin: 0;">

<div style="width: 100%;">
  <table width="100%" cellpadding="0" cellspacing="0" border="0" class="center-on-narrow">
    <tr>
      <td valign="top" align="center">
        <!-- /\/\/\/\/\/\/\/\/\/\ EMAIL BODY : START /\/\/\/\/\/\/\/\/\ -->
        <table role="presentation" cellspacing="0" cellpadding="0" bgcolor="#ffffff" border="0" align="center" width="580" style="margin: auto; width:580px" class="email-container">
          <tr>
            <td class="center-on-narrow">
              <table role="presentation" cellspacing="0" cellpadding="0" bgcolor="#ffffff" border="0" align="center" width="580" style="margin: auto; width:580px" class="email-container">

                <tr bgcolor="#ffffff">
                  <td align="left" class="center-on-narrow" valign="middle" style="vertical-align:middle; padding: 15px 20px;">
                    <img [src]="imageUrl$ | async" width="87" height="86" alt="" border="0"   style="display: block;  height: auto; max-width: 87px; max-height:86px; font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#152a69; line-height:20px; vertical-align:bottom">
                  </td>
                  <td align="left" class="center-on-narrow" valign="middle" style="vertical-align:middle;">
                    <font face="Arial, Helvetica, sans-serif" style="font-size:25px; line-height:30px; color:#000000; font-weight:bold;">ESS Les Paccots</font><br>
                    <font face="Arial, Helvetica, sans-serif" style="font-size:18px; line-height:25px; color:#505050; font-weight:normal;">Ecole Suisse De Ski Les Paccots</font>
                  </td>
                </tr>

              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>

<table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#d9d9d9">
  <tr>
    <td align="center">
      <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" class="email-container">
        <tr>
          <td class="center-on-narrow" style="padding:30px 0px;">
            <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="580" style="margin: auto; width:580px" class="email-container">
              <tr>
                <td class="center-on-narrow" align="left" valign="middle" style="font-size:24px; line-height:29px;" >
                  <font face="Arial, Helvetica, sans-serif" style="font-size:24px; line-height:29px; color:#ffffff; font-weight:bold; text-transform: uppercase;">Confirmación de reserva</font>
                </td>
                <td width="50" class="center-on-narrow" align="right" valign="middle" style="font-size:24px; line-height:29px;" >
                  <font face="Arial, Helvetica, sans-serif" style="font-size:24px; line-height:29px; color:#ed1b66; font-weight:bold; text-transform: uppercase;">#2316</font>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>

<div style="width: 100%;">

  <table width="100%" cellpadding="0" cellspacing="0" border="0" class="center-on-narrow">
    <tr>
      <td valign="top" align="center" style="padding-top:20px; padding-bottom:15px;">
        <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="580" style="margin: auto; width:580px" class="email-container">
          <tr>
            <td class="center-on-narrow">
              <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="580" style="margin: auto; width:580px" class="email-container">
                <tr>
                  <td class="left-on-narrow" align="left" valign="middle">
                    <div [innerHTML]="title"></div>
                    <font face="Arial, Helvetica, sans-serif" style="font-size:16px; line-height:21px; color:#000000;">
                      Hello "<strong>Nombre Cliente</strong>"
                      <br><br>
                      Gracias por reservar con nosotros tu curso o actividad.
                      <br><br>
                      Te enviamos este mensaje para confirmarte la reserva <strong>#2316</strong>, para las siguientes actividades/cursos.
                    </font>
                    <br><br>
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">
                      Nota: El código QR de la reserva es de uso exclusivo de la escuela. Presentalo cuando te lo soliciten.
                    </font>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>

<div style="width: 100%;">
  <table width="100%" cellpadding="0" cellspacing="0" border="0" class="center-on-narrow">
    <tr>
      <td valign="top" align="center" style="padding-top:20px; padding-bottom:0px;">
        <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="580" style="margin: auto; width:580px" class="email-container">
          <tr>
            <td align="center" valign="top" style="padding:15px 20px 15px 20px;" bgcolor="#f4f4f4">

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td>
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td align="left" valign="middle" style="font-size:24px; line-height:29px; padding:0px 0px 15px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:24px; line-height:29px; color:#d2d2d2; font-weight:bold;">Actividad</font>
                        </td>
                        <td width="50" align="right" valign="middle" style="font-size:24px; line-height:29px; padding:0px 0px 15px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:24px; line-height:29px; color:#d9d9d9; font-weight:bold;">01</font>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td valign="top" class="left-on-narrow">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td width="100" align="left" style="font-size:16px; line-height:21px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:16px; line-height:21px; color:#000000;">Nombre</font>
                        </td>
                        <td align="left" style="font-size:18px; line-height:23px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:18px; line-height:23px; color:#000000; font-weight:bold;">Privé Mat 25.03.24</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Tipo:</font>
                        </td>
                        <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Cours Prive Ski</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Date:</font>
                        </td>
                        <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">April 18, 2024 - 09:00 / 09:45</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Participante:</font>
                        </td>
                        <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;"><strong>Cliente Demo</strong> ES - España - 29 Años</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Monitor:</font>
                        </td>
                        <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;"><strong>Cliente Demo</strong> ES - España - 29 Años</font>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td valign="top" width="110" class="left-on-narrow">
                    <img src="images/codigo-qr.gif" width="110" height="110" alt="" border="0"  style="display: block;  height: auto; max-width: 110px; max-height:110px; font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#152a69; line-height:20px; vertical-align:bottom;margin-top: 15px; margin-bottom: 15px;" />
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td style="padding: 10px 0px;">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td style="width: 100%; border-bottom: 1px solid #d2d2d2;"></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td>
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td align="left" valign="middle" style="font-size:24px; line-height:29px; padding-bottom:10px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:24px; line-height:29px; color:#d2d2d2; font-weight:bold;">Prix</font>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td valign="top">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Precio base</font>
                        </td>
                        <td align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">65 CHF</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Extras (x1)</font>
                        </td>
                        <td align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">32 CHF</font>
                        </td>
                      </tr>
                    </table>

                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td style="padding: 10px 0px;">
                          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                              <td style="width: 100%; border-bottom: 1px solid #d2d2d2;"></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>

                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td width="100" align="left" style="font-size:16px; line-height:21px; font-weight: bold; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:16px; line-height:21px; color:#000000; font-weight: bold;">Total</font>
                        </td>
                        <td align="right" style="font-size:16px; line-height:21px; color:#000000; font-weight: bold; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:16px; line-height:21px; color:#000000; font-weight: bold;">97.00 CHF</font>
                        </td>
                      </tr>
                    </table>

                  </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <table width="100%" cellpadding="0" cellspacing="0" border="0" class="center-on-narrow">
    <tr>
      <td valign="top" align="center" style="padding-top:20px; padding-bottom:0px;">
        <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="580" style="margin: auto; width:580px" class="email-container">
          <tr>
            <td align="center" valign="top" style="padding:15px 20px 15px 20px;" bgcolor="#f4f4f4">

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td>
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td align="left" valign="middle" style="font-size:24px; line-height:29px; padding:0px 0px 15px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:24px; line-height:29px; color:#d2d2d2; font-weight:bold;">Actividad</font>
                        </td>
                        <td width="50" align="right" valign="middle" style="font-size:24px; line-height:29px; padding:0px 0px 15px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:24px; line-height:29px; color:#d9d9d9; font-weight:bold;">02</font>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td valign="top" class="left-on-narrow">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td width="100" align="left" style="font-size:16px; line-height:21px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:16px; line-height:21px; color:#000000;">Nombre</font>
                        </td>
                        <td align="left" style="font-size:18px; line-height:23px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:18px; line-height:23px; color:#000000; font-weight:bold;">Privé Mat 25.03.24</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Tipo:</font>
                        </td>
                        <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Cours Prive Ski</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Date:</font>
                        </td>
                        <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">April 18, 2024 - 09:00 / 09:45</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Participante:</font>
                        </td>
                        <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;"><strong>Cliente Demo</strong> ES - España - 29 Años</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Monitor:</font>
                        </td>
                        <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;"><strong>Cliente Demo</strong> ES - España - 29 Años</font>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td valign="top" width="110" class="left-on-narrow">
                    <img src="images/codigo-qr.gif" width="110" height="110" alt="" border="0"  style="display: block;  height: auto; max-width: 110px; max-height:110px; font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#152a69; line-height:20px; vertical-align:bottom; margin-top: 15px; margin-bottom: 15px;" />
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td style="padding: 10px 0px;">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td style="width: 100%; border-bottom: 1px solid #d2d2d2;"></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td>
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td align="left" valign="middle" style="font-size:24px; line-height:29px; padding-bottom:10px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:24px; line-height:29px; color:#d2d2d2; font-weight:bold;">Prix</font>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td valign="top">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Precio base</font>
                        </td>
                        <td align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">65 CHF</font>
                        </td>
                      </tr>
                      <tr>
                        <td width="100" align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Extras (x1)</font>
                        </td>
                        <td align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">32 CHF</font>
                        </td>
                      </tr>
                    </table>

                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td style="padding: 10px 0px;">
                          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                              <td style="width: 100%; border-bottom: 1px solid #d2d2d2;"></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>

                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td width="100" align="left" style="font-size:16px; line-height:21px; font-weight: bold; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:16px; line-height:21px; color:#000000; font-weight: bold;">Total</font>
                        </td>
                        <td align="right" style="font-size:16px; line-height:21px; color:#000000; font-weight: bold; padding:0px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:16px; line-height:21px; color:#000000; font-weight: bold;">97.00 CHF</font>
                        </td>
                      </tr>
                    </table>

                  </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>

<div style="width: 100%;">
  <table width="100%" cellpadding="0" cellspacing="0" border="0" class="center-on-narrow">
    <tr>
      <td valign="top" align="center" style="padding-top:20px; padding-bottom:20px;">
        <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="580" style="margin: auto; width:580px" class="email-container">
          <tr>
            <td align="center" valign="top" style="padding:15px 20px 15px 20px;" bgcolor="#f4f4f4">
              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td valign="top">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td align="left" style="font-size:20px; line-height:25px; padding:0px 0px 15px 0px;" >
                          <font face="Arial, Helvetica, sans-serif" style="font-size:20px; line-height:25px; color:#ed1b66; font-weight: bold;">Resumen</font>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td width="100" align="right" style="font-size:20px; line-height:25px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:20px; line-height:25px; color:#ed1b66; font-weight: bold;">#2316</font>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Actividad 1</font>
                  </td>
                  <td width="200" align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">97.00 CHF</font>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Actividad 2</font>
                  </td>
                  <td width="200" align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">97.00 CHF</font>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Descuento</font>
                  </td>
                  <td width="200" align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">N/D</font>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Boukii Care</font>
                  </td>
                  <td width="200" align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">N/D</font>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Option Remboursement (10%)</font>
                  </td>
                  <td width="200" align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">N/D</font>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td style="padding: 10px 0px;">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td style="width: 100%; border-bottom: 1px solid #d2d2d2;"></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">Subtotal</font>
                  </td>
                  <td width="200" align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">97.00 CHF</font>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">TVA 8.1%</font>
                  </td>
                  <td width="200" align="right" style="font-size:14px; line-height:19px; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:14px; line-height:19px; color:#000000;">97.00 CHF</font>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td style="padding: 10px 0px;">
                    <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                      <tr>
                        <td style="width: 100%; border-bottom: 1px solid #d2d2d2;"></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                <tr>
                  <td align="left" style="font-size:16px; line-height:21px; font-weight: bold; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:16px; line-height:21px; color:#000000; font-weight: bold;">Total</font>
                  </td>
                  <td width="200" align="right" style="font-size:16px; line-height:21px; color:#000000; font-weight: bold; padding:0px 0px;" >
                    <font face="Arial, Helvetica, sans-serif" style="font-size:16px; line-height:21px; color:#000000; font-weight: bold;">97.00 CHF</font>
                  </td>
                </tr>
              </table>

            </td>
          </tr>
        </table>
        <div [innerHTML]="body"></div>
      </td>
    </tr>
  </table>
</div>

<table width="100%" cellpadding="0" cellspacing="0" border="0" class="center-on-narrow">
  <tr>
    <td align="center" style="border-top: 1px solid #000000;">

      <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" class="email-container">
        <tr>
          <td class="center-on-narrow" style="padding:20px 0px;">

            <table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="580" style="margin: auto; width:580px" class="email-container">

              <tr>
                <td width="220" class="center-on-narrow" align="left" valign="top" style="font-size:24px; line-height:29px; padding:0px 0px;" >
                  <img src="images/logo-footer.jpg" width="200" height="52" alt="" border="0"  style="display: block;  height: auto; max-width: 200px; max-height:52px; font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#152a69; line-height:20px; vertical-align:bottom">
                </td>
                <td width="20" class="center-on-narrow" valign="middle" style="border-left:1px solid #000000;">&nbsp;</td>
                <td class="center-on-narrow">
                  <font face="Arial, Helvetica, sans-serif" style="font-size:12px; line-height:17px; color:#000000;">
                    This email has been generated automatically and cannot receive replies.
                  </font>
                  <font face="Arial, Helvetica, sans-serif" style="font-size:12px; line-height:17px; color:#000000;">
                    For more information, contact ESS Les Paccots
                  </font>
                  <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0" style="margin: 10px 0px!important;">
                    <tr>
                      <td width="150" class="center-on-narrow" align="left" valign="middle" style="font-size:20px; line-height:25px; color:#000000; padding:0px 0px;" >
                        <font face="Arial, Helvetica, sans-serif" style="font-size:20px; line-height:25px; color:#000000; font-weight:bold;">123-456-7890</font>
                      </td>
                      <td width="20" class="center-on-narrow" align="left" valign="middle" style="border-left:1px solid #000000;">&nbsp;</td>
                      <td class="center-on-narrow" align="left" valign="middle" style="font-size:15px; line-height:20px; color:#000000;" >
                        <a href="mailto:info@lespaccots.ch" target="_blank" style="color:#000;"><font face="Arial, Helvetica, sans-serif" style="font-size:15px; line-height:20px; color:#000000;">info@lespaccots.ch</font></a>
                      </td>
                    </tr>
                  </table>
                  <font face="Arial, Helvetica, sans-serif" style="font-size:12px; line-height:17px; color:#000000;">
                    Conditions generales de vente
                  </font>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#ed1b66" style="height: 18px;">
        <tr>
          <td style="height: 18px;">&nbsp;</td>
        </tr>
      </table>

      <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0" align="center" class="email-container">
        <tr>
          <td class="center-on-narrow" align="center" style="padding: 20px 0px;">
            <font face="Arial, Helvetica, sans-serif" style="font-size:12px; line-height:17px; color:#000000;">Boukii &copy; 2024</font>
          </td>
        </tr>
      </table>

    </td>
  </tr>
</table>

</body>
</html>
