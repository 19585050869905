<mat-dialog-content>

  <div class="flex-auto">
    <label for="dateFrom">Date de Début</label>

    <mat-form-field appearance="outline">
      <input id="dateFrom" matInput [matDatepicker]="picker"[(ngModel)]="selectedDateFrom" [matDatepickerFilter]="myHolidayFilter" [min]="data.minDate" [max]="data.maxDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [startAt]="today"></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="flex-auto">
    <label for="DateTo">Date de fin</label>

    <mat-form-field appearance="outline">
      <input id="DateTo" matInput [matDatepicker]="pickerTo" [(ngModel)]="selectedDateTo" [matDatepickerFilter]="myHolidayFilter" [min]="selectedDateFrom" [max]="data.maxDate">
      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
      <mat-datepicker #pickerTo [startAt]="today"></mat-datepicker>
    </mat-form-field>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{'cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="{dateFrom: selectedDateFrom, dateTo: selectedDateTo}">Aceptar</button>
</mat-dialog-actions>
