export const MOCK_COUNTRIES = [
  {
      "id": 1,
      "code": "AF",
      "name": "Afghanistan",
      "phonecode": 93,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 2,
      "code": "AL",
      "name": "Albania",
      "phonecode": 355,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 3,
      "code": "DZ",
      "name": "Algeria",
      "phonecode": 213,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 4,
      "code": "AS",
      "name": "American Samoa",
      "phonecode": 1684,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 5,
      "code": "AD",
      "name": "Andorra",
      "phonecode": 376,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 6,
      "code": "AO",
      "name": "Angola",
      "phonecode": 244,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 7,
      "code": "AI",
      "name": "Anguilla",
      "phonecode": 1264,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 8,
      "code": "AQ",
      "name": "Antarctica",
      "phonecode": 0,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 9,
      "code": "AG",
      "name": "Antigua And Barbuda",
      "phonecode": 1268,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 10,
      "code": "AR",
      "name": "Argentina",
      "phonecode": 54,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 11,
      "code": "AM",
      "name": "Armenia",
      "phonecode": 374,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 12,
      "code": "AW",
      "name": "Aruba",
      "phonecode": 297,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 13,
      "code": "AU",
      "name": "Australia",
      "phonecode": 61,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 14,
      "code": "AT",
      "name": "Austria",
      "phonecode": 43,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 15,
      "code": "AZ",
      "name": "Azerbaijan",
      "phonecode": 994,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 16,
      "code": "BS",
      "name": "Bahamas The",
      "phonecode": 1242,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 17,
      "code": "BH",
      "name": "Bahrain",
      "phonecode": 973,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 18,
      "code": "BD",
      "name": "Bangladesh",
      "phonecode": 880,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 19,
      "code": "BB",
      "name": "Barbados",
      "phonecode": 1246,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 20,
      "code": "BY",
      "name": "Belarus",
      "phonecode": 375,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 21,
      "code": "BE",
      "name": "Belgium",
      "phonecode": 32,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 22,
      "code": "BZ",
      "name": "Belize",
      "phonecode": 501,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 23,
      "code": "BJ",
      "name": "Benin",
      "phonecode": 229,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 24,
      "code": "BM",
      "name": "Bermuda",
      "phonecode": 1441,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 25,
      "code": "BT",
      "name": "Bhutan",
      "phonecode": 975,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 26,
      "code": "BO",
      "name": "Bolivia",
      "phonecode": 591,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 27,
      "code": "BA",
      "name": "Bosnia and Herzegovina",
      "phonecode": 387,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 28,
      "code": "BW",
      "name": "Botswana",
      "phonecode": 267,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 29,
      "code": "BV",
      "name": "Bouvet Island",
      "phonecode": 0,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 30,
      "code": "BR",
      "name": "Brazil",
      "phonecode": 55,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 31,
      "code": "IO",
      "name": "British Indian Ocean Territory",
      "phonecode": 246,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 32,
      "code": "BN",
      "name": "Brunei",
      "phonecode": 673,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 33,
      "code": "BG",
      "name": "Bulgaria",
      "phonecode": 359,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 34,
      "code": "BF",
      "name": "Burkina Faso",
      "phonecode": 226,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 35,
      "code": "BI",
      "name": "Burundi",
      "phonecode": 257,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 36,
      "code": "KH",
      "name": "Cambodia",
      "phonecode": 855,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 37,
      "code": "CM",
      "name": "Cameroon",
      "phonecode": 237,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 38,
      "code": "CA",
      "name": "Canada",
      "phonecode": 1,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 39,
      "code": "CV",
      "name": "Cape Verde",
      "phonecode": 238,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 40,
      "code": "KY",
      "name": "Cayman Islands",
      "phonecode": 1345,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 41,
      "code": "CF",
      "name": "Central African Republic",
      "phonecode": 236,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 42,
      "code": "TD",
      "name": "Chad",
      "phonecode": 235,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 43,
      "code": "CL",
      "name": "Chile",
      "phonecode": 56,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 44,
      "code": "CN",
      "name": "China",
      "phonecode": 86,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 45,
      "code": "CX",
      "name": "Christmas Island",
      "phonecode": 61,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 46,
      "code": "CC",
      "name": "Cocos (Keeling) Islands",
      "phonecode": 672,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 47,
      "code": "CO",
      "name": "Colombia",
      "phonecode": 57,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 48,
      "code": "KM",
      "name": "Comoros",
      "phonecode": 269,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 51,
      "code": "CK",
      "name": "Cook Islands",
      "phonecode": 682,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 52,
      "code": "CR",
      "name": "Costa Rica",
      "phonecode": 506,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 53,
      "code": "CI",
      "name": "Cote D'Ivoire (Ivory Coast)",
      "phonecode": 225,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 54,
      "code": "HR",
      "name": "Croatia (Hrvatska)",
      "phonecode": 385,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 55,
      "code": "CU",
      "name": "Cuba",
      "phonecode": 53,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 56,
      "code": "CY",
      "name": "Cyprus",
      "phonecode": 357,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 57,
      "code": "CZ",
      "name": "Czech Republic",
      "phonecode": 420,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 50,
      "code": "CD",
      "name": "Democratic Republic Of The Congo",
      "phonecode": 242,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 58,
      "code": "DK",
      "name": "Denmark",
      "phonecode": 45,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 59,
      "code": "DJ",
      "name": "Djibouti",
      "phonecode": 253,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 60,
      "code": "DM",
      "name": "Dominica",
      "phonecode": 1767,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 61,
      "code": "DO",
      "name": "Dominican Republic",
      "phonecode": 1809,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 62,
      "code": "TP",
      "name": "East Timor",
      "phonecode": 670,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 63,
      "code": "EC",
      "name": "Ecuador",
      "phonecode": 593,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 64,
      "code": "EG",
      "name": "Egypt",
      "phonecode": 20,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 65,
      "code": "SV",
      "name": "El Salvador",
      "phonecode": 503,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 66,
      "code": "GQ",
      "name": "Equatorial Guinea",
      "phonecode": 240,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 67,
      "code": "ER",
      "name": "Eritrea",
      "phonecode": 291,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 68,
      "code": "EE",
      "name": "Estonia",
      "phonecode": 372,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 69,
      "code": "ET",
      "name": "Ethiopia",
      "phonecode": 251,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 70,
      "code": "XA",
      "name": "External Territories of Australia",
      "phonecode": 61,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 71,
      "code": "FK",
      "name": "Falkland Islands",
      "phonecode": 500,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 72,
      "code": "FO",
      "name": "Faroe Islands",
      "phonecode": 298,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 73,
      "code": "FJ",
      "name": "Fiji Islands",
      "phonecode": 679,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 74,
      "code": "FI",
      "name": "Finland",
      "phonecode": 358,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 75,
      "code": "FR",
      "name": "France",
      "phonecode": 33,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 76,
      "code": "GF",
      "name": "French Guiana",
      "phonecode": 594,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 77,
      "code": "PF",
      "name": "French Polynesia",
      "phonecode": 689,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 78,
      "code": "TF",
      "name": "French Southern Territories",
      "phonecode": 0,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 79,
      "code": "GA",
      "name": "Gabon",
      "phonecode": 241,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 80,
      "code": "GM",
      "name": "Gambia The",
      "phonecode": 220,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 81,
      "code": "GE",
      "name": "Georgia",
      "phonecode": 995,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 82,
      "code": "DE",
      "name": "Germany",
      "phonecode": 49,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 83,
      "code": "GH",
      "name": "Ghana",
      "phonecode": 233,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 84,
      "code": "GI",
      "name": "Gibraltar",
      "phonecode": 350,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 85,
      "code": "GR",
      "name": "Greece",
      "phonecode": 30,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 86,
      "code": "GL",
      "name": "Greenland",
      "phonecode": 299,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 87,
      "code": "GD",
      "name": "Grenada",
      "phonecode": 1473,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 88,
      "code": "GP",
      "name": "Guadeloupe",
      "phonecode": 590,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 89,
      "code": "GU",
      "name": "Guam",
      "phonecode": 1671,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 90,
      "code": "GT",
      "name": "Guatemala",
      "phonecode": 502,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 91,
      "code": "XU",
      "name": "Guernsey and Alderney",
      "phonecode": 44,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 92,
      "code": "GN",
      "name": "Guinea",
      "phonecode": 224,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 93,
      "code": "GW",
      "name": "Guinea-Bissau",
      "phonecode": 245,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 94,
      "code": "GY",
      "name": "Guyana",
      "phonecode": 592,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 95,
      "code": "HT",
      "name": "Haiti",
      "phonecode": 509,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 96,
      "code": "HM",
      "name": "Heard and McDonald Islands",
      "phonecode": 0,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 97,
      "code": "HN",
      "name": "Honduras",
      "phonecode": 504,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 98,
      "code": "HK",
      "name": "Hong Kong S.A.R.",
      "phonecode": 852,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 99,
      "code": "HU",
      "name": "Hungary",
      "phonecode": 36,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 100,
      "code": "IS",
      "name": "Iceland",
      "phonecode": 354,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 101,
      "code": "IN",
      "name": "India",
      "phonecode": 91,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 102,
      "code": "ID",
      "name": "Indonesia",
      "phonecode": 62,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 103,
      "code": "IR",
      "name": "Iran",
      "phonecode": 98,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 104,
      "code": "IQ",
      "name": "Iraq",
      "phonecode": 964,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 105,
      "code": "IE",
      "name": "Ireland",
      "phonecode": 353,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 106,
      "code": "IL",
      "name": "Israel",
      "phonecode": 972,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 107,
      "code": "IT",
      "name": "Italy",
      "phonecode": 39,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 108,
      "code": "JM",
      "name": "Jamaica",
      "phonecode": 1876,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 109,
      "code": "JP",
      "name": "Japan",
      "phonecode": 81,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 110,
      "code": "XJ",
      "name": "Jersey",
      "phonecode": 44,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 111,
      "code": "JO",
      "name": "Jordan",
      "phonecode": 962,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 112,
      "code": "KZ",
      "name": "Kazakhstan",
      "phonecode": 7,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 113,
      "code": "KE",
      "name": "Kenya",
      "phonecode": 254,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 114,
      "code": "KI",
      "name": "Kiribati",
      "phonecode": 686,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 115,
      "code": "KP",
      "name": "Korea North",
      "phonecode": 850,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 116,
      "code": "KR",
      "name": "Korea South",
      "phonecode": 82,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 117,
      "code": "KW",
      "name": "Kuwait",
      "phonecode": 965,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 118,
      "code": "KG",
      "name": "Kyrgyzstan",
      "phonecode": 996,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 119,
      "code": "LA",
      "name": "Laos",
      "phonecode": 856,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 120,
      "code": "LV",
      "name": "Latvia",
      "phonecode": 371,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 121,
      "code": "LB",
      "name": "Lebanon",
      "phonecode": 961,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 122,
      "code": "LS",
      "name": "Lesotho",
      "phonecode": 266,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 123,
      "code": "LR",
      "name": "Liberia",
      "phonecode": 231,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 124,
      "code": "LY",
      "name": "Libya",
      "phonecode": 218,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 125,
      "code": "LI",
      "name": "Liechtenstein",
      "phonecode": 423,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 126,
      "code": "LT",
      "name": "Lithuania",
      "phonecode": 370,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 127,
      "code": "LU",
      "name": "Luxembourg",
      "phonecode": 352,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 128,
      "code": "MO",
      "name": "Macau S.A.R.",
      "phonecode": 853,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 129,
      "code": "MK",
      "name": "Macedonia",
      "phonecode": 389,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 130,
      "code": "MG",
      "name": "Madagascar",
      "phonecode": 261,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 131,
      "code": "MW",
      "name": "Malawi",
      "phonecode": 265,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 132,
      "code": "MY",
      "name": "Malaysia",
      "phonecode": 60,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 133,
      "code": "MV",
      "name": "Maldives",
      "phonecode": 960,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 134,
      "code": "ML",
      "name": "Mali",
      "phonecode": 223,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 135,
      "code": "MT",
      "name": "Malta",
      "phonecode": 356,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 136,
      "code": "XM",
      "name": "Man (Isle of)",
      "phonecode": 44,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 137,
      "code": "MH",
      "name": "Marshall Islands",
      "phonecode": 692,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 138,
      "code": "MQ",
      "name": "Martinique",
      "phonecode": 596,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 139,
      "code": "MR",
      "name": "Mauritania",
      "phonecode": 222,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 140,
      "code": "MU",
      "name": "Mauritius",
      "phonecode": 230,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 141,
      "code": "YT",
      "name": "Mayotte",
      "phonecode": 269,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 142,
      "code": "MX",
      "name": "Mexico",
      "phonecode": 52,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 143,
      "code": "FM",
      "name": "Micronesia",
      "phonecode": 691,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 144,
      "code": "MD",
      "name": "Moldova",
      "phonecode": 373,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 145,
      "code": "MC",
      "name": "Monaco",
      "phonecode": 377,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 146,
      "code": "MN",
      "name": "Mongolia",
      "phonecode": 976,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 147,
      "code": "MS",
      "name": "Montserrat",
      "phonecode": 1664,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 148,
      "code": "MA",
      "name": "Morocco",
      "phonecode": 212,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 149,
      "code": "MZ",
      "name": "Mozambique",
      "phonecode": 258,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 150,
      "code": "MM",
      "name": "Myanmar",
      "phonecode": 95,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 151,
      "code": "NA",
      "name": "Namibia",
      "phonecode": 264,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 152,
      "code": "NR",
      "name": "Nauru",
      "phonecode": 674,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 153,
      "code": "NP",
      "name": "Nepal",
      "phonecode": 977,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 154,
      "code": "AN",
      "name": "Netherlands Antilles",
      "phonecode": 599,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 155,
      "code": "NL",
      "name": "Netherlands The",
      "phonecode": 31,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 156,
      "code": "NC",
      "name": "New Caledonia",
      "phonecode": 687,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 157,
      "code": "NZ",
      "name": "New Zealand",
      "phonecode": 64,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 158,
      "code": "NI",
      "name": "Nicaragua",
      "phonecode": 505,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 159,
      "code": "NE",
      "name": "Niger",
      "phonecode": 227,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 160,
      "code": "NG",
      "name": "Nigeria",
      "phonecode": 234,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 161,
      "code": "NU",
      "name": "Niue",
      "phonecode": 683,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 162,
      "code": "NF",
      "name": "Norfolk Island",
      "phonecode": 672,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 163,
      "code": "MP",
      "name": "Northern Mariana Islands",
      "phonecode": 1670,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 164,
      "code": "NO",
      "name": "Norway",
      "phonecode": 47,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 165,
      "code": "OM",
      "name": "Oman",
      "phonecode": 968,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 166,
      "code": "PK",
      "name": "Pakistan",
      "phonecode": 92,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 167,
      "code": "PW",
      "name": "Palau",
      "phonecode": 680,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 168,
      "code": "PS",
      "name": "Palestinian Territory Occupied",
      "phonecode": 970,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 169,
      "code": "PA",
      "name": "Panama",
      "phonecode": 507,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 170,
      "code": "PG",
      "name": "Papua new Guinea",
      "phonecode": 675,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 171,
      "code": "PY",
      "name": "Paraguay",
      "phonecode": 595,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 172,
      "code": "PE",
      "name": "Peru",
      "phonecode": 51,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 173,
      "code": "PH",
      "name": "Philippines",
      "phonecode": 63,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 174,
      "code": "PN",
      "name": "Pitcairn Island",
      "phonecode": 0,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 175,
      "code": "PL",
      "name": "Poland",
      "phonecode": 48,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 176,
      "code": "PT",
      "name": "Portugal",
      "phonecode": 351,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 177,
      "code": "PR",
      "name": "Puerto Rico",
      "phonecode": 1787,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 178,
      "code": "QA",
      "name": "Qatar",
      "phonecode": 974,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 49,
      "code": "CG",
      "name": "Republic Of The Congo",
      "phonecode": 242,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 179,
      "code": "RE",
      "name": "Reunion",
      "phonecode": 262,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 180,
      "code": "RO",
      "name": "Romania",
      "phonecode": 40,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 181,
      "code": "RU",
      "name": "Russia",
      "phonecode": 70,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 182,
      "code": "RW",
      "name": "Rwanda",
      "phonecode": 250,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 183,
      "code": "SH",
      "name": "Saint Helena",
      "phonecode": 290,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 184,
      "code": "KN",
      "name": "Saint Kitts And Nevis",
      "phonecode": 1869,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 185,
      "code": "LC",
      "name": "Saint Lucia",
      "phonecode": 1758,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 186,
      "code": "PM",
      "name": "Saint Pierre and Miquelon",
      "phonecode": 508,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 187,
      "code": "VC",
      "name": "Saint Vincent And The Grenadines",
      "phonecode": 1784,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 188,
      "code": "WS",
      "name": "Samoa",
      "phonecode": 684,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 189,
      "code": "SM",
      "name": "San Marino",
      "phonecode": 378,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 190,
      "code": "ST",
      "name": "Sao Tome and Principe",
      "phonecode": 239,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 191,
      "code": "SA",
      "name": "Saudi Arabia",
      "phonecode": 966,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 192,
      "code": "SN",
      "name": "Senegal",
      "phonecode": 221,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 193,
      "code": "RS",
      "name": "Serbia",
      "phonecode": 381,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 194,
      "code": "SC",
      "name": "Seychelles",
      "phonecode": 248,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 195,
      "code": "SL",
      "name": "Sierra Leone",
      "phonecode": 232,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 196,
      "code": "SG",
      "name": "Singapore",
      "phonecode": 65,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 197,
      "code": "SK",
      "name": "Slovakia",
      "phonecode": 421,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 198,
      "code": "SI",
      "name": "Slovenia",
      "phonecode": 386,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 199,
      "code": "XG",
      "name": "Smaller Territories of the UK",
      "phonecode": 44,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 200,
      "code": "SB",
      "name": "Solomon Islands",
      "phonecode": 677,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 201,
      "code": "SO",
      "name": "Somalia",
      "phonecode": 252,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 202,
      "code": "ZA",
      "name": "South Africa",
      "phonecode": 27,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 203,
      "code": "GS",
      "name": "South Georgia",
      "phonecode": 0,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 204,
      "code": "SS",
      "name": "South Sudan",
      "phonecode": 211,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 205,
      "code": "ES",
      "name": "España",
      "phonecode": 34,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 206,
      "code": "LK",
      "name": "Sri Lanka",
      "phonecode": 94,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 207,
      "code": "SD",
      "name": "Sudan",
      "phonecode": 249,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 208,
      "code": "SR",
      "name": "Suriname",
      "phonecode": 597,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 209,
      "code": "SJ",
      "name": "Svalbard And Jan Mayen Islands",
      "phonecode": 47,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 210,
      "code": "SZ",
      "name": "Swaziland",
      "phonecode": 268,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 211,
      "code": "SE",
      "name": "Sweden",
      "phonecode": 46,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 212,
      "code": "CH",
      "name": "Switzerland",
      "phonecode": 41,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 213,
      "code": "SY",
      "name": "Syria",
      "phonecode": 963,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 214,
      "code": "TW",
      "name": "Taiwan",
      "phonecode": 886,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 215,
      "code": "TJ",
      "name": "Tajikistan",
      "phonecode": 992,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 216,
      "code": "TZ",
      "name": "Tanzania",
      "phonecode": 255,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 217,
      "code": "TH",
      "name": "Thailand",
      "phonecode": 66,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 218,
      "code": "TG",
      "name": "Togo",
      "phonecode": 228,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 219,
      "code": "TK",
      "name": "Tokelau",
      "phonecode": 690,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 220,
      "code": "TO",
      "name": "Tonga",
      "phonecode": 676,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 221,
      "code": "TT",
      "name": "Trinidad And Tobago",
      "phonecode": 1868,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 222,
      "code": "TN",
      "name": "Tunisia",
      "phonecode": 216,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 223,
      "code": "TR",
      "name": "Turkey",
      "phonecode": 90,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 224,
      "code": "TM",
      "name": "Turkmenistan",
      "phonecode": 7370,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 225,
      "code": "TC",
      "name": "Turks And Caicos Islands",
      "phonecode": 1649,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 226,
      "code": "TV",
      "name": "Tuvalu",
      "phonecode": 688,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 227,
      "code": "UG",
      "name": "Uganda",
      "phonecode": 256,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 228,
      "code": "UA",
      "name": "Ukraine",
      "phonecode": 380,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 229,
      "code": "AE",
      "name": "United Arab Emirates",
      "phonecode": 971,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 230,
      "code": "GB",
      "name": "United Kingdom",
      "phonecode": 44,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 231,
      "code": "US",
      "name": "United States",
      "phonecode": 1,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 232,
      "code": "UM",
      "name": "United States Minor Outlying Islands",
      "phonecode": 1,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 233,
      "code": "UY",
      "name": "Uruguay",
      "phonecode": 598,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 234,
      "code": "UZ",
      "name": "Uzbekistan",
      "phonecode": 998,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 235,
      "code": "VU",
      "name": "Vanuatu",
      "phonecode": 678,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 236,
      "code": "VA",
      "name": "Vatican City State (Holy See)",
      "phonecode": 39,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 237,
      "code": "VE",
      "name": "Venezuela",
      "phonecode": 58,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 238,
      "code": "VN",
      "name": "Vietnam",
      "phonecode": 84,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 239,
      "code": "VG",
      "name": "Virgin Islands (British)",
      "phonecode": 1284,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 240,
      "code": "VI",
      "name": "Virgin Islands (US)",
      "phonecode": 1340,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 241,
      "code": "WF",
      "name": "Wallis And Futuna Islands",
      "phonecode": 681,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 242,
      "code": "EH",
      "name": "Western Sahara",
      "phonecode": 212,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 243,
      "code": "YE",
      "name": "Yemen",
      "phonecode": 967,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 244,
      "code": "YU",
      "name": "Yugoslavia",
      "phonecode": 38,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 245,
      "code": "ZM",
      "name": "Zambia",
      "phonecode": 260,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  },
  {
      "id": 246,
      "code": "ZW",
      "name": "Zimbabwe",
      "phonecode": 263,
      "continent_name": "Europe",
      "created_at": null,
      "updated_at": null
  }
]
