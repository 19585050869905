<mat-dialog-content>

  <!-- Select con las fechas basadas en un índice -->
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Fecha</mat-label>
    <mat-select [(ngModel)]="selectedDateIndex">
        <mat-option *ngFor="let i of generatedNumbers; let idx = index" [value]="idx">
            {{ i + 1 }} Fecha reservada(s)
        </mat-option>
    </mat-select>
</mat-form-field>

  <!-- Input para el porcentaje con un límite de 100 -->
  <mat-form-field appearance="outline" style="width: 100%;">
    <input type="number" matInput [(ngModel)]="percentage" placeholder="Porcentaje (0 - 100)" min="0" max="100">
    <mat-error *ngIf="percentage > 100 || percentage < 0">The value must be set between 0 and 100</mat-error>
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{'cancel' | translate }}</button>
  <!-- Enviando la fecha y el porcentaje al cerrar el diálogo -->
  <button mat-button [mat-dialog-close]="{dateIndex: selectedDateIndex+1, percentage: percentage}" [disabled]="(selectedDateIndex === null || percentage === null) || (percentage > 100 || percentage < 0)">{{'confirm' | translate}}</button>
</mat-dialog-actions>
