export const LEVELS = [{
  "id": 181,
  "league": "SKV",
  "level": "test",
  "name": "Ptit Loup",
  "annotation": "PT",
  "degree_order": 0,
  "progress": 0,
  "color": "#1C482C",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},
{
  "id": 183,
  "league": "SKV",
  "level": "test",
  "name": "JN",
  "annotation": "JN",
  "degree_order": 1,
  "progress": 0,
  "color": "#1C482C",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 182,
  "league": "SKV",
  "level": "test",
  "name": "Débutant Kid Village",
  "annotation": "DKV",
  "degree_order": 2,
  "progress": 0,
  "color": "#1C482C",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 184,
  "league": "BLEU",
  "level": "test",
  "name": "Prince / Pricesse Bleu",
  "annotation": "PB",
  "degree_order": 3,
  "progress": 0,
  "color": "#0E3991",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 185,
  "league": "BLEU",
  "level": "test",
  "name": "Roi / Reine Bleu",
  "annotation": "RB",
  "degree_order": 4,
  "progress": 0,
  "color": "#0E3991",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 186,
  "league": "BLEU",
  "level": "test",
  "name": "Star Bleu",
  "annotation": "SB",
  "degree_order": 5,
  "progress": 0,
  "color": "#0E3991",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 187,
  "league": "ROUGE",
  "level": "test",
  "name": "R1",
  "annotation": "R1",
  "degree_order": 6,
  "progress": 0,
  "color": "#572830",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 188,
  "league": "ROUGE",
  "level": "test",
  "name": "R2",
  "annotation": "R2",
  "degree_order": 7,
  "progress": 0,
  "color": "#572830",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 189,
  "league": "ROUGE",
  "level": "test",
  "name": "R3",
  "annotation": "R3",
  "degree_order": 8,
  "progress": 0,
  "color": "#572830",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 190,
  "league": "NOIR",
  "level": "test",
  "name": "Prince / Pricesse Noir",
  "annotation": "PN",
  "degree_order": 9,
  "progress": 0,
  "color": "#000000",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 191,
  "league": "Academy",
  "level": "test",
  "name": "Race",
  "annotation": "ACA",
  "degree_order": 10,
  "progress": 0,
  "color": "#7d7c7c",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 192,
  "league": "Academy",
  "level": "test",
  "name": "Freestyle",
  "annotation": "ACA",
  "degree_order": 11,
  "progress": 0,
  "color": "#7d7c7c",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
},{
  "id": 193,
  "league": "Academy",
  "level": "test",
  "name": "Freeride",
  "annotation": "ACA",
  "degree_order": 12,
  "progress": 0,
  "color": "#7d7c7c",
  "age_min": 1,
  "age_max": 99,
  "active": false,
  "school_id": 1,
  "sport_id": 1,
  "created_at": "2023-11-28T12:12:38.000000Z",
  "updated_at": "2023-11-28T18:28:28.000000Z",
  "deleted_at": null
}
];
