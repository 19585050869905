<h2 mat-dialog-title>{{ 'Email Preview' | translate }}</h2>
<mat-dialog-content>
  <div >
    <h3>{{ data.subject }}</h3>
    <vex-confirm-mail></vex-confirm-mail>
  </div>
  <!-- Similar sections for other languages -->
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'Close' | translate }}</button>
</mat-dialog-actions>
