<vex-secondary-toolbar current="">
  <vex-breadcrumbs [crumbs]="[
    {icon:'cursos'},
    {text:'courses.title', title: true,  link: '/courses'},
    {text: defaults.id, subtitle: true,  link: '/courses/detail/'+defaults.id},
    {text: (mode !== 'update' ? 'courses.new' : 'courses.update'), subtitle: true},
    ]" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<mat-spinner *ngIf="loading" style="margin: 0 auto"></mat-spinner>

<div [@stagger]="true"class="p-gutter container"style="max-width: none" *ngIf="!loading && ((defaults.course_type === null || defaults.course_type === 2 && mode === 'create') || (defaults.course_type === 2 && mode === 'update'))">
  <div class="flex flex-col sm:flex-row gap-4" style="width: 100%;float:left; margin: 0 2% 0 0">
    <div @fadeInUp class="card flex-auto" style="width: 100%;">
      <div class="px-6 py-4 border-b flex items-center">
        <div style="float: left;width: 50%;">
          <h2 class="title m-0">{{(mode === 'update' ? 'courses.update' : 'courses.new') |translate }}</h2>
        </div>
        <div style="float: left;width: 50%;">
          <div style="float:right">
            Online <mat-slide-toggle [(ngModel)]="defaults.online" color="accent"></mat-slide-toggle>
          </div>
          <div style="float:right">
            {{'options' | translate}} <mat-slide-toggle [(ngModel)]="defaults.options"></mat-slide-toggle>
          </div>
          <div style="float:right">
            {{'status' | translate}} <mat-slide-toggle [(ngModel)]="defaults.active"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <mat-horizontal-stepper #stepper="matVerticalStepper" [linear]="true">
        <ng-template matStepperIcon="edit">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>

        <ng-template matStepperIcon="done">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>
        <mat-step [stepControl]="courseTypeFormGroup" *ngIf="mode === 'create'">
          <ng-template matStepLabel>{{'course_info' | translate }}</ng-template>

          <div [formGroup]="courseTypeFormGroup">

            <div  style="width:50%; float:left" >
              <div class="flex-auto">
                <mat-label>{{'season' | translate }} - {{'sport' | translate }} <span style="color:red">(*)</span></mat-label><br>
                <mat-radio-group formControlName="sportType" (change)="filterSportsByType()">
                  <mat-radio-button *ngFor="let sport of sportTypeData.reverse()" class="mr-4" [value]="sport.id">{{sport.name}}</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="flex-auto" *ngIf="sportTypeSelected > -1" style="margin:1% 0 0 4%; width:100%; float:left">
                <div style="float:left; width: 20%" *ngFor="let item of sportDataList.reverse()">
                  <span style="width: 100%;margin: 0 auto">
                    <img style="margin: 0 auto; border-radius: 20%;border: solid 1px; cursor:pointer"
                         [ngStyle]="{
                      'background': defaults.sport_id === item.sport_id ? '#e91e63' : '#e6e6e6',
                      'border': defaults.sport_id === item.sport_id ? '#fff' : '#808080'
                    }"
                         [src]="item.sport_id !== defaults.sport_id ? item.icon_unselected : item.icon_selected" (click)="selectSport(item)">
                  </span>
                  <p style="text-align: center;">{{ item.name }}</p>
                </div>
              </div>
            </div>

            <div style="margin: 2% 0 0 2%;width: 48%;float: left;" *ngIf="defaults.sport_id">

              <div style="width: 100%;float:left" >
                <mat-card class="custom-card" [class.selected-collectif]="defaults.course_type === 1" (click)="setCourseType('collectif', 1)" >
                  <mat-card-content>
                    <p>{{'course_colective' |translate }}</p>
                  </mat-card-content>
                </mat-card>
                <mat-checkbox [checked]="false" style="margin: 10px 0 0 0;float: left;"[disabled]="defaults.course_type === null || defaults.course_type !== 1" (click)="setFlexibility($event)">
                  {{'flexidates' | translate}}

                </mat-checkbox>
                <div style="float: left; margin: 17px">
                  <mat-icon svgIcon="mat:info"></mat-icon>
                </div>
              </div>

              <div style="width: 100%;float:left">
                <mat-card class="custom-card" [class.selected-prive]="defaults.course_type === 2" (click)="setCourseType('privee', 2)">
                  <mat-card-content>
                    <p>{{'course_private' |translate }}</p>
                  </mat-card-content>
                </mat-card>
                <mat-checkbox [checked]="false" style="margin: 10px 0 0 0;float: left;" [disabled]="defaults.course_type === null || defaults.course_type !== 2" (click)="setFlexibility($event)">
                  {{'flexidates' | translate}}
                </mat-checkbox>
                <div style="float: left; margin: 17px">
                  <mat-icon svgIcon="mat:info"></mat-icon>
                </div>
              </div>
              <div style="width: 100%;float:left">
                <mat-card class="custom-card" [class.selected-activity]="defaults.course_type === 3" (click)="setCourseType('activity', 3)">
                  <mat-card-content>
                    <p>{{'activity' |translate }}</p>
                  </mat-card-content>
                </mat-card>
                <div style="float: left; margin: 17px">
                  <mat-icon svgIcon="mat:info"></mat-icon>
                </div>
              </div>
            </div>


          </div>
          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseTypeFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button [disabled]="courseTypeFormGroup.invalid" color="primary" mat-raised-button matStepperNext>
              {{'next' | translate}}
            </button>
          </div>
        </mat-step>

        <mat-step [stepControl]="courseInfoFormGroup">
          <ng-template matStepLabel>{{'general_data' | translate}}</ng-template>

          <div class="px-6 py-4 flex flex-col" [formGroup]="courseInfoFormGroup">

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 50px 0 0 0;">

              <mat-tab-group  style="margin-top:-50px" [(selectedIndex)]="selectedTabNameIndex" (selectedTabChange)="onTabNameChanged($event);">
                <mat-tab label="FR">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 0">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name" matInput required type="text" [(ngModel)]="defaults.translations.fr.name">
                      <mat-error *ngIf="courseInfoFormGroup.controls?.course_name?.errors?.['required']">
                        {{'courses.errors.coursename' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="EN">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 1">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_en" matInput required type="text" [(ngModel)]="defaults.translations.en.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="DE">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 2">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_de" matInput required type="text" [(ngModel)]="defaults.translations.de.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="ES">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 3">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_es" matInput required type="text" [(ngModel)]="defaults.translations.es.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="IT">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 4">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_it" matInput required type="text" [(ngModel)]="defaults.translations.it.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
              </mat-tab-group>

              <div class="flex-auto" style="width: 15%">
                <label for="courseName">{{(defaults.is_flexible ? 'min_price' : 'price' )|translate}} ({{defaults.currency}}) <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input id="courseName" formControlName="price" matInput [required]="!defaults.is_flexible" [type]="defaults.is_flexible ? 'text' : 'number'" [(ngModel)]="defaults.price" [readonly]="defaults.is_flexible"
                         [value]="defaults.is_flexible ? 'FLEXIBLE' : null">
                  <mat-error *ngIf="courseInfoFormGroup.controls.price.errors?.['required'] && !defaults.is_flexible">
                    {{'courses.errors.price' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="flex-auto" style="width: 30%">
                <label for="station">{{'station' | translate}}<span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input type="text" id="station" placeholder="{{'choose_station' | translate}}" matInput [formControl]="myControlStations" [matAutocomplete]="autoStation" [(ngModel)]="defaults.station_id">
                  <mat-autocomplete #autoStation="matAutocomplete" [displayWith]="displayFnStation">
                    <mat-option *ngFor="let option of filteredStations | async" [value]="option" (onSelectionChange)="setStation(option)">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>

                </mat-form-field>
                <mat-error *ngIf="myControlStations.touched && myControlStations.value === null">
                  {{'choose_station' | translate}}
                </mat-error>
              </div>

            </div>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

              <div class="flex-auto" style="width: 60%;">

                <div style="float:left; width: 100%">
                  <div class="image-container" style="float: left;width: 50%;">

                    <img [src]="defaults.image !== null && defaults.image !== '' ? defaults.image : '../../../../assets/img/no-image.jpg'"
                         alt="Image Preview" width="400" height="290" style="width: 400px;height: 290px;object-fit: contain;margin:0 auto">
                  </div>

                  <div class="input-container">

                    <input type="file" formControlName="image" placeholder="Select an image" (change)="onFileChanged($event)" id="file"/>
                    <label for="file" class="btn-1"><mat-icon svgIcon="mat:cloud_upload"></mat-icon></label>
                    <p style="font-size: 12px">{{'image_max' | translate}}</p>
                    <p style="font-size: 12px">{{'image_format' | translate}}</p>
                    <p style="font-size: 12px">{{'image_recomendation' | translate}}</p>
                  </div>
                </div>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

              <div class="flex-auto" style="width: 40%">
                <label for="ageFrom">{{'courses.min_age' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input id="ageFrom" type="number" placeholder="{{'courses.min_age' | translate}}" formControlName="ageFrom" aria-label="Number" matInput [(ngModel)]="defaults.age_min"/>
                  <mat-error *ngIf="courseInfoFormGroup?.controls?.ageFrom?.errors?.['required']">
                    {{'courses.errors.min_age' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="flex-auto" style="width: 40%">
                <label for="ageTo">{{'courses.max_age' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input id="ageTo" type="number" placeholder="{{'courses.max_age' | translate}}" formControlName="ageTo" aria-label="Number" matInput [(ngModel)]="defaults.age_max"/>
                  <mat-error *ngIf="courseInfoFormGroup?.controls?.ageTo?.errors?.['required']">
                    {{'courses.errors.max_age' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin-top: 3%">

              <mat-tab-group style="width: 100%" [(selectedIndex)]="selectedTabDescIndex" (selectedTabChange)="onTabDesChanged($event);" preserveContent>
                <mat-tab label="FR">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 0">
                    <label for="summary_fr" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_fr" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.fr.short_description" formControlName="summary"></angular-editor>
                    <mat-error *ngIf="courseInfoFormGroup?.controls?.summary?.errors?.['required']">
                      {{'courses.errors.summary' | translate}}
                    </mat-error>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 0">
                    <label for="description_fr" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_fr" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.fr.description" formControlName="description"></angular-editor>
                    <mat-error *ngIf="courseInfoFormGroup?.controls?.description?.errors?.['required']">
                      {{'courses.errors.desc' | translate}}
                    </mat-error>
                  </div>
                </mat-tab>
                <mat-tab label="EN">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 1">
                    <label for="summary_en" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_en" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.en.short_description" formControlName="summary_en"></angular-editor>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 1">
                    <label for="description_en" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_en" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.en.description" formControlName="description_en"></angular-editor>
                  </div>
                </mat-tab>
                <mat-tab label="DE">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 2">
                    <label for="summary_de" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_de" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.de.short_description" formControlName="summary_de"></angular-editor>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 2">
                    <label for="description_de" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_de" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.de.description" formControlName="description_de"></angular-editor>
                  </div>
                </mat-tab>
                <mat-tab label="ES">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 3">
                    <label for="summary_es" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_es" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.es.short_description" formControlName="summary_es"></angular-editor>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 3">
                    <label for="description_es" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_es" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.es.description" formControlName="description_es"></angular-editor>
                  </div>
                </mat-tab>
                <mat-tab label="IT">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 4">
                    <label for="summary_it" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_it" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.it.short_description" formControlName="summary_it"></angular-editor>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 4">
                    <label for="description_it" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_it" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.it.description" formControlName="description_it"></angular-editor>
                  </div>
                </mat-tab>
              </mat-tab-group>

            </div>
          </div>
          <div class="actions flex items-center justify-between gap-2" style="width: 100%">
            <!-- Botón de traducción a la izquierda -->
            <button mat-raised-button color="primary" (click)="translateCurrentTabToOthers()">
              {{ 'translate_ia' | translate }}
            </button>

            <!-- Contenedor para alinear los botones restantes a la derecha -->
            <div class="flex items-center gap-2">
              <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">
                Reset
              </button>
              <button color="primary" (click)="checkStep2PrivateNoFlex(stepper)" mat-raised-button>
                {{'next' | translate}}
              </button>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="courseInfoPriveFormGroup" [completed]="false" [editable]="true">
          <ng-template matStepLabel>{{'course_info' | translate}}</ng-template>

          <mat-spinner *ngIf="loadingTable" style="margin: 0 auto;"></mat-spinner>
          <div class="px-6 py-4 flex flex-col" [formGroup]="courseInfoPriveFormGroup" *ngIf="!loadingTable">

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <div class="flex-auto" style="width: 50%;">

                <label for="dateFromPrivate" *ngIf="defaults.is_flexible">{{'courses.date_reservable' | translate}} <span style="color:red">(*)</span></label>
                <label for="dateFromPrivate" *ngIf="!defaults.is_flexible">{{'from' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateFromPrivate" [matDatepicker]="pickerFrom" [matDatepickerFilter]="myHolidayFilter" formControlName="fromDate" [min]="mode !== 'update' ? minDate : ''" [max]="maxDate" [(ngModel)]="defaults.date_start_res">
                  <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFrom [startAt]="minDate"></mat-datepicker>

                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.fromDate.errors?.['required']">
                    <span *ngIf="defaults.is_flexible">
                      {{'courses.errors.date_reservable' | translate}}
                    </span>
                    <span *ngIf="!defaults.is_flexible">
                      {{'courses.errors.from' | translate}}
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex-auto" style="width: 50%;">

                <label for="dateToPrivate" *ngIf="defaults.is_flexible">{{'courses.date_reservable_to' | translate}} <span style="color:red">(*)</span></label>
                <label for="dateToPrivate" *ngIf="!defaults.is_flexible">{{'to' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateToPrivate" [matDatepicker]="pickerTo" formControlName="toDate" [matDatepickerFilter]="myHolidayFilter" [min]="courseInfoPriveFormGroup.value.fromDate" [max]="maxDate"
                         [(ngModel)]="defaults.date_end_res"
                         [disabled]="courseInfoPriveFormGroup.value.fromDate === null">
                  <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo [startAt]="courseInfoPriveFormGroup.value.fromDate"></mat-datepicker>

                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.toDate.errors?.['required']">
                    <span *ngIf="defaults.is_flexible">
                      {{'courses.errors.date_reservable_to' | translate}}
                    </span>
                    <span *ngIf="!defaults.is_flexible">
                      {{'courses.errors.to' | translate}}
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <div class="flex-auto" style="width: 50%;">

                <label for="durationPrivate">{{'courses.dur_min' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="durationPrivate" formControlName="duration" placeholder="{{'courses.dur_min' | translate}}" matInput [matAutocomplete]="autoTimesPrivate" [(ngModel)]="defaults.duration">
                  <mat-autocomplete #autoTimesPrivate="matAutocomplete">
                    <mat-option *ngFor="let duration of durations" [value]="duration.text">
                      {{duration.text}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="flex-auto" style="width: 50%;">

                <label for="participants">{{'max_pax' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <mat-label>{{'max_pax' | translate}}</mat-label>
                  <input id="participants" formControlName="participants" matInput required type="number" [(ngModel)]="defaults.max_participants">
                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.participants.errors?.['required']">
                    {{'courses.errors.max_pax' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" *ngIf="!defaults.is_flexible">
              <div class="flex-auto">

                <label for="fromHour">{{'start_hour' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="fromHour" formControlName="fromHour" placeholder="{{'start_hour' | translate}}" matInput [matAutocomplete]="autoHourFrom" [(ngModel)]="defaults.hour_min">
                  <mat-autocomplete #autoHourFrom="matAutocomplete">
                    <mat-option *ngFor="let hour of hours" [value]="hour">
                      {{hour}}
                    </mat-option>
                  </mat-autocomplete>

                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.fromHour.errors?.['required']">
                    {{'courses.errors.start_hour' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex-auto">

                <label for="maxDuration">{{'end_hour' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="toHour" formControlName="maxDuration" placeholder="{{'end_hour' | translate}}" matInput [matAutocomplete]="autoHourTo" [(ngModel)]="defaults.hour_max">
                  <mat-autocomplete #autoHourTo="matAutocomplete">
                    <mat-option *ngFor="let hour of filteredToHours" [value]="hour">
                      {{hour}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.maxDuration.errors?.['required']">
                    {{'courses.errors.end_hour' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" *ngIf="defaults.is_flexible">
              <div class="flex-auto">

                <label for="durationPrivate" style="font-size: 20px;">{{'courses.when' | translate}}</label>
              </div>
              <div class="flex-auto">

                <mat-checkbox (change)="onCheckboxChange('unique')" [checked]="periodeUnique" formControlName="periodeUnique">{{'courses.uniperiod' | translate}}</mat-checkbox>
              </div>
              <div class="flex-auto">

                <mat-checkbox (change)="onCheckboxChange('multiple')" [checked]="periodeMultiple" formControlName="periodeMultiple">{{'courses.multiperiod' | translate}}</mat-checkbox>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" *ngIf="defaults.course_type === 2 && !periodeMultiple && defaults.is_flexible">
              <div class="flex-auto" style="width:50%">

                <label for="dateFromPrivate" *ngIf="defaults.is_flexible">{{'from' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateFromPrivate" [matDatepicker]="pickerFromUnique" formControlName="fromDateUnique" [matDatepickerFilter]="myHolidayFilter" [min]="mode !== 'update' ? minDate : ''"
                         [max]="maxDate" [(ngModel)]="defaults.date_start">
                  <mat-datepicker-toggle matSuffix [for]="pickerFromUnique"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFromUnique [startAt]="minDate"></mat-datepicker>

                  <mat-error *ngIf="courseInfoPriveFormGroup?.controls?.fromDateUnique?.errors?.['required']">
                    {{'courses.errors.from' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex-auto" style="width:50%">

                <label for="dateToPrivate" *ngIf="defaults.is_flexible">{{'to' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateToPrivate" [matDatepicker]="pickerToUnique" formControlName="toDateUnique" [matDatepickerFilter]="myHolidayFilter" [min]="courseInfoPriveFormGroup?.value?.fromDateUnique" [max]="maxDate" [(ngModel)]="defaults.date_end"
                         [disabled]="courseInfoPriveFormGroup.value.fromDate === null">
                  <mat-datepicker-toggle matSuffix [for]="pickerToUnique"></mat-datepicker-toggle>
                  <mat-datepicker #pickerToUnique [startAt]="courseInfoPriveFormGroup?.value?.fromDateUnique"></mat-datepicker>

                  <mat-error *ngIf="courseInfoPriveFormGroup?.controls?.toDateUnique?.errors?.['required']">
                    {{'courses.errors.to' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="sm:flex-row gap-2 sm:gap-6" style="margin: 5% 0 0 0" *ngIf="defaults.course_type === 2 && periodeMultiple && defaults.is_flexible">
              <div style="width: 100%">

                <table #privateDatesTable id="private-date-table" mat-table [dataSource]="dataSourceDatePrivate" matSort style="border: 1px solid #e6e6e6">

                  <ng-container matColumnDef="dateFrom">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'courses.date_start' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> <span [ngStyle]="{'text-decoration': mode === 'update' && (element.active === 0 || !element.active) ? 'line-through' : ''}">{{element.dateFrom}}</span> </td>
                  </ng-container>

                  <ng-container matColumnDef="dateTo">
                    <th mat-header-cell *matHeaderCellDef> {{'courses.date_end' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> <span [ngStyle]="{'text-decoration': mode === 'update' && (element.active === 0 || !element.active) ? 'line-through' : ''}">{{element.dateTo}}</span> </td>
                  </ng-container>

                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element; let idx = index">
                      <mat-icon svgIcon="mat:delete" style="cursor:pointer" *ngIf="(element.active || element.active === 1) || !element.id" (click)="removePrivateDate(idx, element.main, element.period)"></mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedPrivateDateColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedPrivateDateColumns;let i = index"
                      [hidden]="!row.main && row?.period !== selectedPeriod" (click)="selectPeriod(row.mainPeriod, row.main)" [style.cursor]="row.main ? 'pointer': 'default'"></tr>
                </table>
              </div>
              <div style="width: 100%; margin: 2% 0 0 0; float:left;border: 1px solid #e6e6e6;padding: 1%;">
                <div style="width: 5%; float:left;">

                  <button class="add-date" style="cursor:pointer" mat-fab (click)="openDialogPrivateDate()">
                    <mat-icon svgIcon="mat:add" class="icon-date"></mat-icon>
                  </button>
                </div>
                <div style="width: 95%; float:left;margin: 1% 0 0 0;">
                  <p> {{'add_date' | translate }}</p>
                </div>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 3% 0 0 0" >

              <div class="flex-auto">

                <label for="all-days">{{'days' | translate}}</label>
                <div style="width: 100%">

                  <mat-checkbox id="all-days" [checked]="areAllTrue(defaults?.settings?.weekDays)" (change)="addWeekDay($event, 'all')">Touts selectionner</mat-checkbox>
                </div>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" >

              <div class="flex-auto" style="padding-left: 5%">

                <div style="width: 100%">
                  <ul style="float: left;width: 25%;">
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.monday" (change)="addWeekDay($event, 'monday')">{{'Lundi' | translate}}</mat-checkbox></li>
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.tuesday" (change)="addWeekDay($event, 'tuesday')">{{'Mardi' | translate}}</mat-checkbox></li>
                  </ul>
                  <ul style="float: left;width: 25%;">
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.wednesday" (change)="addWeekDay($event, 'wednesday')">{{'Mercedi' | translate}}</mat-checkbox></li>
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.thursday" (change)="addWeekDay($event, 'thursday')">{{'Jeudi' | translate}}</mat-checkbox></li>
                  </ul>
                  <ul style="float: left;width: 25%;">
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.friday" (change)="addWeekDay($event, 'friday')">{{'Vendredi' | translate}}</mat-checkbox></li>
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.saturday" (change)="addWeekDay($event, 'saturday')">{{'Samedi' | translate}}</mat-checkbox></li>
                  </ul>
                  <ul style="float: left;width: 25%;">
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.sunday" (change)="addWeekDay($event, 'sunday')">{{'Diamanche' | translate}}</mat-checkbox></li>

                  </ul>
                </div>
              </div>

            </div>

            <!--<div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin-top: 5%;" *ngIf="this.defaults.is_flexible">
              <div class="flex-auto">

                <label for="minDuration">DURÉE MINIMALE</label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="minDuration" formControlName="minDuration" placeholder="DURÉE MINIMALE" matInput [matAutocomplete]="autoTimesMinDuration">
                  <mat-autocomplete #autoTimesMinDuration="matAutocomplete">
                    <mat-option *ngFor="let duration of durations" [value]="duration.text">
                      {{duration.text}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="flex-auto">

                <label for="maxDuration">DURÉE MAXIMALE</label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="maxDuration" formControlName="maxDuration" placeholder="DURÉE MAXIMALE" matInput [matAutocomplete]="autoTimesMmaxDuration">
                  <mat-autocomplete #autoTimesMmaxDuration="matAutocomplete">
                    <mat-option *ngFor="let duration of filteredMaxDurations" [value]="duration.text">
                      {{duration.text}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>-->

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" *ngIf="defaults.is_flexible">
              <div class="flex-auto">

                <label for="fromHour">{{'start_hour' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="fromHour" formControlName="fromHour" placeholder="{{'start_hour' | translate}}" matInput [matAutocomplete]="autoHourFrom" [(ngModel)]="defaults.hour_min">
                  <mat-autocomplete #autoHourFrom="matAutocomplete">
                    <mat-option *ngFor="let hour of hours" [value]="hour">
                      {{hour}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="flex-auto">

                <label for="maxDuration">{{'end_hour' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="maxDuration" formControlName="toHour" placeholder="{{'end_hour' | translate}}" matInput [matAutocomplete]="autoHourTo" [(ngModel)]="defaults.hour_max">
                  <mat-autocomplete #autoHourTo="matAutocomplete">
                    <mat-option *ngFor="let hour of filteredToHours" [value]="hour">
                      {{hour}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>


            <!--
            <div class="sm:flex-row gap-2 sm:gap-6" style="margin: 5% 0 0 0" *ngIf="defaults.course_type === 2 && this.defaults.is_flexible && this.periodeMultiple">
              <div class="flex-auto" style="width: 100%">
                <label for="table-reduction">{{'courses.reduction_title' | translate}}</label>

                <table #privateReductionTable id="table-private-reduction" mat-table [dataSource]="dataSourceReductionsPrivate" matSort style="border: 1px solid #e6e6e6">

                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                  </ng-container>

                  <ng-container matColumnDef="percentage">
                    <th mat-header-cell *matHeaderCellDef> {{'reduction' | translate}} (%) </th>
                    <td mat-cell *matCellDef="let element"> {{element.percentage}} </td>
                  </ng-container>

                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element; let idx = index">
                      <mat-icon svgIcon="mat:delete" (click)="removePrivateReduction(element, idx)"></mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedReductionsColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedReductionsColumns;"></tr>
                </table>
              </div>
              <div style="width: 100%; margin: 2% 0 0 0; float:left;border: 1px solid #e6e6e6;padding: 1%;">
                <div style="width: 5%; float:left;">

                  <button class="add-date" mat-fab (click)="openDialogPrivateReductions()">
                    <mat-icon svgIcon="mat:add" class="icon-date"></mat-icon>
                  </button>
                </div>
                <div style="width: 95%; float:left;margin: 1% 0 0 0;">
                  <p> {{'add_date' | translate}}</p>
                </div>
              </div>
            </div>-->
          </div>


          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button color="primary" mat-raised-button *ngIf="defaults.is_flexible" (click)="checkStep3PrivateFlex(stepper)">
              {{'next' | translate}}
            </button>
            <button color="primary" mat-raised-button matStepperNext (click)="save()" *ngIf="!defaults.is_flexible">
              {{'save' | translate}}
            </button>
          </div>
        </mat-step>
        <mat-step *ngIf="defaults.course_type === 2 && this.defaults.is_flexible">

          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button color="primary" mat-raised-button (click)="save()">
              {{'save' | translate}}
            </button>
          </div>
          <ng-template matStepLabel>{{'prices' | translate}}</ng-template>
          <div class="p-6">

            <mat-form-field appearance="outline" class="flex-auto">
              <input matInput placeholder="Enter people number" type="number" [value]="people" [(ngModel)]="people" (ngModelChange)="updateTable(people, false)">
            </mat-form-field>

            <div class="table-container" >
              <table mat-table [dataSource]="dataSourceFlexiblePrices" class="mat-elevation-z8" #scrollContainer>
                <caption>{{'price' | translate}} {{'in' | translate}} CFH</caption>

                <!-- Intervalo Column -->
                <ng-container matColumnDef="intervalo">
                  <th mat-header-cell *matHeaderCellDef> {{'interval' | translate}} </th>
                  <td mat-cell *matCellDef="let row"> {{row.intervalo}} </td>
                </ng-container>

                <!-- Personas Columns -->
                <ng-container *ngFor="let col of displayedColumnsFlexiblePrices.slice(1)" [matColumnDef]="col">
                  <th mat-header-cell *matHeaderCellDef> {{col}} </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-form-field appearance="outline" class="flex-auto">
                      <input class="flex-auto" matInput [(ngModel)]="row[col]" style="margin-top:3%">
                    </mat-form-field>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsFlexiblePrices"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsFlexiblePrices;"></tr>
              </table>
            </div>
          </div>

          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button color="primary" mat-raised-button (click)="save()">
              {{'save' | translate}}
            </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>

    </div>
  </div>

</div>


<!-- Colectivos -->
<div [@stagger]="true"class="p-gutter container"style="max-width: none" *ngIf="!loading && ((defaults.course_type === 1 && mode === 'create') || (defaults.course_type === 1 && mode === 'update'))">
  <div class="flex flex-col sm:flex-row gap-4" style="width: 100%;float:left; margin: 0 2% 0 0">
    <div @fadeInUp class="card flex-auto">
      <div class="px-6 py-4 border-b flex items-center">
        <div style="float: left;width: 50%;">
          <h2 class="title m-0">{{'courses.new' |translate }}</h2>
        </div>
        <div style="float: left;width: 50%;">
          <div style="float:right">
            Online <mat-slide-toggle [(ngModel)]="defaults.online" color="accent"></mat-slide-toggle>
          </div>
          <div style="float:right">
            {{'options' | translate}} <mat-slide-toggle [(ngModel)]="defaults.options"></mat-slide-toggle>
          </div>
          <div style="float:right">
            {{'status' | translate}} <mat-slide-toggle [(ngModel)]="defaults.active"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <mat-horizontal-stepper #stepper="matHorizontalStepper" [linear]="true" (selectionChange)="loadData($event)">
        <ng-template matStepperIcon="edit">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>

        <ng-template matStepperIcon="done">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>

        <mat-step [stepControl]="courseTypeFormGroup" *ngIf="mode === 'create'">
          <ng-template matStepLabel>{{'course_info' | translate}}</ng-template>

          <div [formGroup]="courseTypeFormGroup">

            <div style="width:50%; float:left" >
              <div class="flex-auto">
                <mat-label>{{'season' | translate}} - {{'sport' | translate}} <span style="color:red">(*)</span></mat-label><br>
                <mat-radio-group formControlName="sportType" (change)="filterSportsByType()">
                  <mat-radio-button *ngFor="let sport of sportTypeData.reverse()" class="mr-4" [value]="sport.id">{{sport.name}}</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="flex-auto" *ngIf="sportTypeSelected > -1" style="margin:1% 0 0 4%; width:100%; float:left">
                <div style="float:left; width: 20%" *ngFor="let item of sportDataList.reverse()">
                  <span style="width: 100%;margin: 0 auto">
                    <img style="margin: 0 auto; border-radius: 20%;border: solid 1px; cursor:pointer"
                         [ngStyle]="{
                      'background': defaults.sport_id === item.sport_id ? '#e91e63' : '#e6e6e6',
                      'border': defaults.sport_id === item.sport_id ? '#fff' : '#808080'
                    }"
                         [src]="item.sport_id !== defaults.sport_id ? item.icon_unselected : item.icon_selected" (click)="selectSport(item)">
                  </span>
                  <p style="text-align: center;">{{ item.name }}</p>
                </div>
              </div>
            </div>

            <div style="margin: 2% 0 0 2%;width: 48%;float: left;"  *ngIf="defaults.sport_id">

              <div style="width: 100%;float:left">
                <mat-card class="custom-card" [class.selected-collectif]="defaults.course_type === 1" (click)="setCourseType('collectif', 1)" >
                  <mat-card-content>
                    <p>{{'course_colective' | translate}}</p>
                  </mat-card-content>
                </mat-card>
                <mat-checkbox [checked]="false" style="margin: 10px 0 0 0;float: left;"[disabled]="defaults.course_type === null ||defaults.course_type !== 1" (click)="setFlexibility($event)">
                  {{'flexidates' | translate}}

                </mat-checkbox>
                <div style="float: left; margin: 17px">
                  <mat-icon svgIcon="mat:info"></mat-icon>
                </div>
              </div>

              <div style="width: 100%;float:left">
                <mat-card class="custom-card" [class.selected-prive]="defaults.course_type === 2" (click)="setCourseType('privee', 2)">
                  <mat-card-content>
                    <p>{{'course_private' | translate}}</p>
                  </mat-card-content>
                </mat-card>
                <mat-checkbox [checked]="false" style="margin: 10px 0 0 0;float: left;" [disabled]="defaults.course_type === null || defaults.course_type !== 2" (click)="setFlexibility($event)">
                  {{'flexidates' | translate}}
                </mat-checkbox>
                <div style="float: left; margin: 17px">
                  <mat-icon svgIcon="mat:info"></mat-icon>
                </div>
              </div>
              <div style="width: 100%;float:left">
                <mat-card class="custom-card" [class.selected-activity]="defaults.course_type === 3" (click)="setCourseType('activity', 3)">
                  <mat-card-content>
                    <p>{{'activity' | translate}}</p>
                  </mat-card-content>
                </mat-card>
                <div style="float: left; margin: 17px">
                  <mat-icon svgIcon="mat:info"></mat-icon>
                </div>
              </div>
            </div>

          </div>
          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseTypeFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button [disabled]="courseTypeFormGroup.invalid" color="primary" mat-raised-button matStepperNext>
              {{'next' | translate}}
            </button>
          </div>
        </mat-step>


        <mat-step [stepControl]="courseInfoFormGroup">
          <ng-template matStepLabel>{{'general_data' | translate }}</ng-template>

          <div class="px-6 py-4 flex flex-col" [formGroup]="courseInfoFormGroup">

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 50px 0 0 0;">

              <mat-tab-group style="margin-top:-50px" [(selectedIndex)]="selectedTabNameIndex" (selectedTabChange)="onTabNameChanged($event);">
                <mat-tab label="FR">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 0">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name" matInput required type="text" [(ngModel)]="defaults.translations.fr.name">
                      <mat-error *ngIf="courseInfoFormGroup.controls?.course_name?.errors?.['required']">
                        {{'courses.errors.coursename' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="EN">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 1">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_en" matInput required type="text" [(ngModel)]="defaults.translations.en.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="DE">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 2">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_de" matInput required type="text" [(ngModel)]="defaults.translations.de.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="ES">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 3">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_es" matInput required type="text" [(ngModel)]="defaults.translations.es.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="IT">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 4">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_it" matInput required type="text" [(ngModel)]="defaults.translations.it.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
              </mat-tab-group>
              <div class="flex-auto" style="width: 15%">
                <label for="price" *ngIf="defaults.is_flexible">{{'price_per_date' | translate}} ({{defaults.currency}}) <span style="color:red">(*)</span></label>
                <label for="price" *ngIf="!defaults.is_flexible">{{'price' | translate}} ({{defaults.currency}}) <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input id="price" formControlName="price" matInput required type="number" [(ngModel)]="defaults.price">
                  <mat-error *ngIf="courseInfoFormGroup.controls?.price?.errors?.['required']">
                    {{'courses.errors.price' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="flex-auto" style="width: 30%">
                <label for="station">{{'station' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input type="text" id="station" placeholder="{{'choose_station' | translate}}" matInput [formControl]="myControlStations" [matAutocomplete]="autoStation" [(ngModel)]="defaults.station_id">
                  <mat-autocomplete #autoStation="matAutocomplete" [displayWith]="displayFnStation">
                    <mat-option *ngFor="let option of filteredStations | async" [value]="option"  (onSelectionChange)="setStation(option)">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-error *ngIf="myControlStations.touched && myControlStations.value === null">
                  {{'choose_station' | translate}}
                </mat-error>
              </div>

            </div>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

              <div class="flex-auto" style="width: 60%;">

                <div style="float:left; width: 100%">
                  <div class="image-container" style="float: left;width: 50%;">

                    <img [src]="defaults.image !== null && defaults.image !== '' ? defaults.image : '../../../../assets/img/no-image.jpg'" alt="Image Preview" width="400" height="290" style=" height: 150px;width: 400px;height: 290px;object-fit: contain;">
                  </div>

                  <div class="input-container">

                    <input type="file" formControlName="image" placeholder="Select an image" (change)="onFileChanged($event)" id="file"/>
                    <label for="file" class="btn-1"><mat-icon svgIcon="mat:cloud_upload"></mat-icon></label>
                    <p style="font-size: 12px">{{'image_max' | translate}}</p>
                    <p style="font-size: 12px">{{'image_format' | translate}}</p>
                    <p style="font-size: 12px">{{'image_recomendation' | translate}}</p>
                  </div>
                </div>
              </div>
            </div><div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin-top: 3%">

            <mat-tab-group style="width: 100%" [(selectedIndex)]="selectedTabDescIndex" (selectedTabChange)="onTabDesChanged($event);" preserveContent>
              <mat-tab label="FR">
                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 0">
                  <label for="summary_fr" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="summary_fr" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.fr.short_description" formControlName="summary"></angular-editor>
                  <mat-error *ngIf="courseInfoFormGroup?.controls?.summary?.errors?.['required']">
                    {{'courses.errors.summary' | translate}}
                  </mat-error>
                </div>

                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 0">
                  <label for="description_fr" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="description_fr" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.fr.description" formControlName="description"></angular-editor>
                  <mat-error *ngIf="courseInfoFormGroup?.controls?.description?.errors?.['required']">
                    {{'courses.errors.desc' | translate}}
                  </mat-error>
                </div>
              </mat-tab>
              <mat-tab label="EN">
                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 1">
                  <label for="summary_en" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="summary_en" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.en.short_description" formControlName="summary_en"></angular-editor>
                </div>

                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 1">
                  <label for="description_en" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="description_en" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.en.description" formControlName="description_en"></angular-editor>
                </div>
              </mat-tab>
              <mat-tab label="DE">
                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 2">
                  <label for="summary_de" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="summary_de" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.de.short_description" formControlName="summary_de"></angular-editor>
                </div>

                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 2">
                  <label for="description_de" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="description_de" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.de.description" formControlName="description_de"></angular-editor>
                </div>
              </mat-tab>
              <mat-tab label="ES">
                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 3">
                  <label for="summary_es" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="summary_es" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.es.short_description" formControlName="summary_es"></angular-editor>
                </div>

                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 3">
                  <label for="description_es" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="description_es" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.es.description" formControlName="description_es"></angular-editor>
                </div>
              </mat-tab>
              <mat-tab label="IT">
                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 4">
                  <label for="summary_it" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="summary_it" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.it.short_description" formControlName="summary_it" [config]="editorConfig"></angular-editor>
                </div>

                <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 4">
                  <label for="description_it" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                  <angular-editor id="description_it" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.it.description" formControlName="description_it"></angular-editor>
                </div>
              </mat-tab>
            </mat-tab-group>

          </div>
          </div>
          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <div class="actions flex items-center justify-between gap-2" style="width: 100%">
              <!-- Botón de traducción a la izquierda -->
              <button mat-raised-button color="primary" (click)="translateCurrentTabToOthers()">
                {{ 'translate_ia' | translate }}
              </button>

              <!-- Contenedor para alinear los botones restantes a la derecha -->
              <div class="flex items-center gap-2">
                <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">
                  Reset
                </button>
                <button color="primary" mat-raised-button (click)="checkStep2ColectiveNoFlex(stepper)">
              <span *ngIf="mode === 'create'">
                {{ 'next'| translate}}

              </span>
                  <span *ngIf="mode !== 'create'">
                {{ 'save'| translate}}

              </span>
                </button>
              </div>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="courseConfigForm" *ngIf="defaults.course_type === 1">
          <ng-template matStepLabel>{{'courses.config' | translate }}</ng-template>

          <div class="px-6 py-4 flex flex-col" [formGroup]="courseConfigForm">

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <div class="flex-auto">

                <label for="dateFromPrivate" *ngIf="defaults.is_flexible">{{'courses.date_reservable' | translate}} <span style="color:red">(*)</span></label>
                <label for="dateFromPrivate" *ngIf="!defaults.is_flexible">{{'courses.date_reservable' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateFromPrivate" [matDatepicker]="pickerDateFrom" formControlName="fromDate" [min]="minDate" [matDatepickerFilter]="myHolidayFilter" [max]="maxDate" [(ngModel)]="defaults.date_start_res">
                  <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
                  <mat-datepicker #pickerDateFrom [startAt]="minDate"></mat-datepicker>

                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.fromDate.errors?.['required']">
                    {{'courses.errors.date_reservable' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex-auto">

                <label for="dateToPrivate" *ngIf="defaults.is_flexible">{{'courses.date_reservable_to' | translate}} <span style="color:red">(*)</span></label>
                <label for="dateToPrivate" *ngIf="!defaults.is_flexible">{{'courses.date_reservable_to' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateToPrivate" [matDatepicker]="pickerDateTo" formControlName="toDate" [matDatepickerFilter]="myHolidayFilter" [min]="courseConfigForm.value.fromDate" [max]="maxDate" [(ngModel)]="defaults.date_end_res"
                         [disabled]="courseConfigForm.value.fromDate === null">
                  <mat-datepicker-toggle matSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
                  <mat-datepicker #pickerDateTo [startAt]="courseConfigForm.value.fromDate"></mat-datepicker>
                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.fromDate.errors?.['required']">
                    {{'courses.errors.date_reservable' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

              <div class="flex-auto">

                <label for="participants">{{'max_pax' | translate}}. <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input id="participants" formControlName="participants" matInput required type="number" [(ngModel)]="defaults.max_participants">
                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.participants.errors?.['required']">
                    {{'courses.errors.max_pax' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="sm:flex-row gap-2 sm:gap-6" *ngIf="defaults.course_type === 1">
              <div class="flex-auto" style="width: 100%" *ngIf="dataSource.data.length > 0">
                <label for="table-dates">{{'dates'|translate }}</label>

                <table #dateTable id="table-dates" mat-table [dataSource]="dataSource" matSort style="border: 1px solid #e6e6e6">

                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> <span [ngStyle]="{'text-decoration': mode === 'update' && element.id && (element.active === 0 || !element.active) ? 'line-through' : ''}">{{element.date}} </span></td>
                  </ng-container>

                  <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'duration' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> <span [ngStyle]="{'text-decoration': mode === 'update' && element.id && (element.active === 0 || !element.active) ? 'line-through' : ''}">{{element.duration}}</span> </td>
                  </ng-container>

                  <ng-container matColumnDef="hour">
                    <th mat-header-cell *matHeaderCellDef> {{'hour' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> <span [ngStyle]="{'text-decoration': mode === 'update' && element.id && (element.active === 0 || !element.active) ? 'line-through' : ''}">{{element.hour}}</span> </td>
                  </ng-container>

                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-icon svgIcon="mat:delete" style="cursor:pointer" *ngIf="(element.active || element.active === 1) || !element.id" (click)="removeteDate(i)"></mat-icon>
                      <mat-icon svgIcon="mat:add" style="cursor:pointer" *ngIf="!element.active" (click)="activateDate(i)"></mat-icon>
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-icon svgIcon="mat:edit" style="cursor:pointer" *ngIf="(element.active || element.active === 1) || !element.id" (click)="editDate(i, element)"></mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>

              <div style="width: 100%; margin: 2% 0 0 0; float:left;border: 1px solid #e6e6e6;padding: 1%;">
                <div style="width: 5%; float:left;">

                  <button class="add-date" mat-fab (click)="openDialog()">
                    <mat-icon svgIcon="mat:add" class="icon-date"></mat-icon>
                  </button>
                </div>
                <div style="width: 95%; float:left;margin: 1% 0 0 0;">
                  <p> {{'add_date' | translate }}</p>
                </div>
              </div>
            </div>

            <div class="sm:flex-row gap-2 sm:gap-6" style="margin: 8% 0 0 0" *ngIf="defaults.course_type === 1 && this.defaults.is_flexible">
              <div class="flex-auto" style="width: 100%">
                <label for="table-reduction">{{'courses.reduction_title' | translate}}</label>

                <table #reductionTable id="table-reduction" *ngIf="dataSource.data.length > 0" mat-table [dataSource]="dataSourceReductions" matSort style="border: 1px solid #e6e6e6">

                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'date' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                  </ng-container>

                  <ng-container matColumnDef="percentage">
                    <th mat-header-cell *matHeaderCellDef> {{'reduction' | translate}} (%) </th>
                    <td mat-cell *matCellDef="let element"> {{element.percentage}} </td>
                  </ng-container>

                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element; let idx = index">
                      <mat-icon svgIcon="mat:delete" (click)="removeReduction(element, idx)"></mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedReductionsColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedReductionsColumns;"></tr>
                </table>
              </div>

              <div style="width: 100%; margin: 2% 0 0 0; float:left;border: 1px solid #e6e6e6;padding: 1%;">
                <div style="width: 5%; float:left;">

                  <button class="add-date" mat-fab (click)="openDialogReductions()">
                    <mat-icon svgIcon="mat:add" class="icon-date"></mat-icon>
                  </button>
                </div>
                <div style="width: 95%; float:left;margin: 1% 0 0 0;">
                  <p> {{'add_date' | translate}}</p>
                </div>
              </div>
            </div>

          </div>

          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button color="primary" mat-raised-button (click)="checkStep3ColectiveNoFlex(stepper)" *ngIf="mode==='create'">
              {{'next' | translate}}
            </button>
            <button color="primary" mat-raised-button (click)="update()" *ngIf="mode==='update'">
              {{'save' | translate}}
            </button>
          </div>
        </mat-step>

        <mat-step *ngIf="mode === 'create'">
          <ng-template matStepLabel>{{'courses.select_levels' | translate}}</ng-template>

          <div class="flex flex-col sm:flex-row gap-4" style="width: 100%;float:left;" *ngIf="defaults.course_type === 1">
            <div @fadeInUp class="card flex-auto">
              <mat-card style="padding: 0 3%;">
                <mat-card-header style="display:block">
                  <div style="width: 100%;float:left;">
                    <mat-card-title style="float: left;width: 35%;">{{'course_detail' | translate }}</mat-card-title>
                    <div style="float:right;width: 65%">
                      <div style="float: right;width: 25px;border-radius: 100%;height: 25px;margin: 0 0 0 25px;" [ngStyle]="{'background-color': defaults.active ? '#CEE741' : '#e6e6e6'}"></div>
                      <div style="float: right;width: 25px;border-radius: 100%;height: 25px;margin: 0 0 0 25px;" [ngStyle]="{'background-color': defaults.options ? '#CEE741' : '#e6e6e6'}"></div>
                      <div style="float: right;width: 25px;border-radius: 100%;height: 25px;margin: 0;" [ngStyle]="{'background-color': defaults.online ? '#CEE741' : '#e6e6e6'}"></div>
                    </div>
                  </div>
                  <div style="width:100%; margin-top:5%;float:left">
                    <div style="width: 100%;">
                      <p style="float: left;width: 80%;text-align: left;"><b>{{'from' | translate }}</b></p>
                      <p style="float: left;width: 20%;text-align: right;"><b>{{'to' | translate }}</b></p>
                    </div>
                    <div style="width: 100%;">
                      <p style="float: left;width: 50%;text-align: left;"><b>{{defaults.date_start_res |date: 'dd/MM/YYYY'}}</b> </p>
                      <p style="float: left;width: 50%;text-align: right;"><b>{{defaults.date_end_res |date: 'dd/MM/YYYY'}}</b> </p>
                    </div>
                  </div>
                  <mat-divider style="float: left;width: 100%;margin: 2% 0 0 0;"></mat-divider>

                  <div style="width:100%; margin-top:5%;float:left">
                    <div style="width: 100%;">
                      <p style="float: left;width: 80%;text-align: left;"><b>{{'price' | translate }}</b></p>
                      <p style="float: left;width: 20%;text-align: right;"><b>{{'participants' | translate }}</b></p>
                    </div>
                    <div style="width: 100%;">
                      <p style="float: left;width: 50%;text-align: left;"><b>{{defaults.price}}</b> {{defaults.currency}}</p>
                      <p style="float: left;width: 50%;text-align: right;"><b>{{defaults.max_participants}}</b></p>
                    </div>
                  </div>
                  <mat-divider style="float: left;width: 100%;margin: 2% 0 0 0;"></mat-divider>
                  <div style="float: left; width: 100%;margin-top: 5%;">
                    <ul>
                      <li *ngFor="let item of defaults.course_dates; let i = index">
                        {{ daysDatesLevels[i].dateString }}
                        <i style="text-align: right;float:right">
                          {{ item.hour_start}} - {{ item.hour_end }}
                        </i>

                      </li>
                    </ul>
                  </div>
                  <mat-divider style="float: left;width: 100%;margin: 2% 0 0 0;"></mat-divider>
                </mat-card-header>
                <mat-card-content style="margin-top: 5%;">

                  <div style="width: 100%;float:left;">
                    <mat-card-title style="float: left;width: 35%;">{{'course_detail' | translate}}</mat-card-title>
                  </div>

                  <div style="width: 100%;float:left;">
                    <ng-container *ngFor="let colorKey of colorKeys">
                      <div style="float: left; width: 100%;margin-top:3%" *ngFor="let groupLevel of groupedByColor[colorKey]; let groupIndex = index">
                        <div style="float: left;width: 10%;border-radius: 11%;height: 100px;padding: 2%;color: #fff;text-align: center;">
                          <mat-slide-toggle (change)="activeGroup($event, groupLevel)" [checked]="groupLevel.active"></mat-slide-toggle>
                        </div>
                        <div style="float: left;width: 16%;">
                          <div style="width:100%;text-align: center;border-radius: 0;height: 100px;padding: 2%;color: #fff;" [style.background-color]="colorKey">
                            {{groupLevel.annotation}} - {{groupLevel.name}}
                          </div>
                          <mat-form-field appearance="outline" class="flex-auto" style="width: 100%; margin-top: 10%;" *ngIf="groupLevel.active">
                            <mat-select placeholder="Nivel minimo del profesor" (selectionChange)="setLevelTeacher(groupLevel)" [value]="calculateMonitorLevel(groupLevel)">
                              <mat-option *ngFor="let item of levels" [value]="item">
                                {{ item.league }} - {{ item.name }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div  style="float: left;width: 16%;margin: 0 0 0 2%;" *ngIf="groupLevel.active">
                          <div style="width:100%;border-radius: 0;height: 100px;padding: 2%;color: #000;background: #e6e6e6;text-align: center;" >
                            {{'groups' | translate}} {{defaults?.course_dates[0]?.groups[groupIndex]?.subgroups.length}}g
                          </div>
                          <div style="width: 100%; margin-top: 10%;height: 60px">
                            <button mat-button color="accent" style="width: 100%;border-radius: 5px;color:#fff;background: #ff3085;height: 100%" (click)="addSubGroup(groupLevel)">Añadir subgrupo</button>
                          </div>
                        </div>

                        <div  style="float: left;width: 16%;margin: 0 0 0 2%;" *ngIf="groupLevel.active">
                          <div style="width:100%;border-radius: 0;height: 100px;padding: 2%;color: #000;background: #e6e6e6;text-align: center;" >
                            {{'age' | translate}}
                          </div>
                          <form [formGroup]="rangeForm" novalidate>
                            <mat-form-field appearance="outline" style="width: 45%; margin-top: 10%;margin-right: 10%">
                              <input type="number" placeholder="Min." aria-label="Number" matInput [min]="0" (blur)="setMinAge($event, groupLevel)" [value]="groupLevel.age_min"
                                     [value]="calculateAgeMin(groupLevel)"/>
                              <mat-error *ngIf="minAge.errors?.['min']">
                                {{'courses.errors.min_age_levels' | translate}}
                              </mat-error>
                              <mat-error *ngIf="minAge.errors?.['required']">
                                {{'mandatory' | translate}}
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="width: 45%; margin-top: 10%">
                              <input type="number" placeholder="Max" aria-label="Number" matInput [min]="0" (blur)="setMaxAge($event, groupLevel)" [value]="groupLevel.age_max"
                                     [value]="calculateAgeMax(groupLevel)"/>
                              <mat-error *ngIf="maxAge.errors?.['ageRange']">
                                {{'courses.errors.max_age_range' | translate}}
                              </mat-error>
                              <mat-error *ngIf="maxAge.errors?.['max']">
                                {{'courses.errors.max_age_levels' | translate}}
                              </mat-error>
                              <mat-error *ngIf="maxAge.errors?.['required']">
                                {{'mandatory' | translate}}
                              </mat-error>
                            </mat-form-field>
                          </form>
                        </div>
                        <div  style="float: left;width: 16%;margin: 0 0 0 2%;" *ngIf="groupLevel.active">
                          <div style="width:100%;border-radius: 0;height: 100px;padding: 2%;color: #000;background: #e6e6e6;text-align: center;" >
                            {{'duration' | translate}}<br>
                            {{calculateFormattedDuration(defaults.course_dates[daySelectedIndex].hour_start, defaults.course_dates[daySelectedIndex].hour_end)}}
                          </div>
                          <mat-form-field appearance="outline" style="width: 100%; margin-top: 10%;margin-right: 10%">
                            <input type="text" placeholder="Duración" aria-label="Number" matInput
                                   [value]="calculateFormattedDuration(defaults.course_dates[daySelectedIndex].hour_start, defaults.course_dates[daySelectedIndex].hour_end)" [readonly]="true"/>
                          </mat-form-field>
                        </div>
                        <div  style="float: left;width: 16%;margin: 0 0 0 2%;" *ngIf="groupLevel.active">
                          <div style="width:100%;border-radius: 0%;height: 100px;padding: 2%;color: #000;background: #e6e6e6;text-align: center;" >
                            {{'students' | translate}} <strong>0/{{defaults.max_participants}}</strong>
                          </div>
                          <mat-form-field appearance="outline" class="flex-auto" style="width: 100%; margin-top: 10%">
                            <input type="number" placeholder="Max per Group" aria-label="Number" matInput (blur)="setSubGroupPax($event, groupLevel)"/>
                            <mat-error *ngIf="groupLevel.max_participants > this.defaults.max_participants">
                              {{'courses.errors.students'  | translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <ng-container *ngIf="groupLevel.active">

                          <div style="width:100%;float:left;overflow: scroll;" *ngFor="let subGroup of readSubGroups(groupLevel.id); let subGroupIndex = index">
                            <div style="float:left; width: 100%; margin-top: 5%;padding: 0 0% 0 10%">
                              <div>
                                <ul style="display: flex">
                                  <li style="float: left; width: 20%;font-size: 18px">
                                    <span [style.color]="colorKey"><strong>{{groupLevel.annotation}}</strong> - {{groupLevel.name}} - {{ subGroupIndex + 1 }}</span>
                                  </li>
                                  <li *ngFor="let dayLevel of daysDatesLevels; let dayIndex = index;"
                                      (click)="selectItem(dayLevel, dayIndex, subGroupIndex, groupLevel)"
                                      [style.color]="selectedItem === dayLevel.dateString && dayIndex === daySelectedIndex && subGroupIndex === subGroupSelectedIndex ? '#FF3085' : ''"
                                      style="float: left; width: 13%; text-align: center; cursor: pointer;">
                                    {{ dayLevel.dateString }}
                                  </li>

                                </ul>
                              </div>
                            </div>
                            <div style="float:left; width: 100%; margin-top: 5%;padding: 0 0% 0 10%" *ngIf="subGroupSelectedIndex !== null">
                              <div>
                                <ul>
                                  <li style="float: left; width: 20%;" (click)="subGroupSelectedIndex = subGroupIndex;">
                                    <label for="monitor" style="font-size: 20px;">{{'monitor' | translate}}</label>

                                    <mat-spinner *ngIf="loadingMonitors"> </mat-spinner>
                                    <mat-form-field appearance="outline" class="flex-auto" *ngIf="!loadingMonitors">

                                      <input type="text" id="monitor" placeholder="Select monitor" matInput [matAutocomplete]="autoMoniteur" [value]="getMonitorValue(groupLevel, subGroupIndex, daySelectedIndex)"/>
                                      <mat-autocomplete #autoMoniteur="matAutocomplete" [displayWith]="displayFnMoniteurs" >

                                        <ng-container *ngFor="let monitor of filteredMonitors | async">
                                          <mat-option *ngIf="!checkIfExistInDate(daySelectedIndex, monitor, groupLevel)"
                                                      (onSelectionChange)="setSubGroupMonitor($event, monitor, groupLevel, subGroupSelectedIndex, daySelectedIndex)">
                                            {{monitor && monitor?.first_name ? monitor?.first_name : '' }} {{monitor && monitor?.last_name ? monitor?.last_name : ''}}
                                          </mat-option>
                                        </ng-container>
                                      </mat-autocomplete>
                                      <mat-icon matPrefix svgIcon="mat:person"></mat-icon>
                                    </mat-form-field>
                                  </li>
                                  <li style="float: left; width: 13%;" *ngFor="let item of daysDatesLevels">
                                    <div style="background-color: #CEE741;width: 25px;border-radius: 100%;height: 25px;margin: 0 auto;"></div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <mat-divider class="text-border" style="float: left;width: 100%;border: 4px solid #f0f0f0;"></mat-divider>

                          </div>

                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button color="primary" mat-raised-button (click)="save()">
              {{'save' | translate}}
            </button>
          </div>
        </mat-step>

      </mat-horizontal-stepper>
    </div>
  </div>
</div>

<!-- Activites -->

<div [@stagger]="true"class="p-gutter container"style="max-width: none" *ngIf="!loading && ((defaults.course_type === 3 && mode === 'create') || (defaults.course_type === 3 && mode === 'update'))">
  <div class="flex flex-col sm:flex-row gap-4" style="width: 100%;float:left; margin: 0 2% 0 0">
    <div @fadeInUp class="card flex-auto" style="width: 100%;">
      <div class="px-6 py-4 border-b flex items-center">
        <div style="float: left;width: 50%;">
          <h2 class="title m-0">{{(mode === 'update' ? 'courses.update' : 'courses.new') |translate }}</h2>
        </div>
        <div style="float: left;width: 50%;">
          <div style="float:right">
            Online <mat-slide-toggle [(ngModel)]="defaults.online" color="accent"></mat-slide-toggle>
          </div>
          <div style="float:right">
            {{'options' | translate}} <mat-slide-toggle [(ngModel)]="defaults.options"></mat-slide-toggle>
          </div>
          <div style="float:right">
            {{'status' | translate}} <mat-slide-toggle [(ngModel)]="defaults.active"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <mat-horizontal-stepper #stepper="matVerticalStepper" [linear]="true">
        <ng-template matStepperIcon="edit">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>

        <ng-template matStepperIcon="done">
          <mat-icon svgIcon="mat:done_all"></mat-icon>
        </ng-template>
        <mat-step [stepControl]="courseTypeFormGroup" *ngIf="mode === 'create'">
          <ng-template matStepLabel>{{'course_info' | translate }}</ng-template>

          <div [formGroup]="courseTypeFormGroup">

            <div  style="width:50%; float:left" >
              <div class="flex-auto">
                <mat-label>{{'season' | translate }} - {{'sport' | translate }} <span style="color:red">(*)</span></mat-label><br>
                <mat-radio-group formControlName="sportType" (change)="filterSportsByType()">
                  <mat-radio-button *ngFor="let sport of sportTypeData.reverse()" class="mr-4" [value]="sport.id">{{sport.name}}</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="flex-auto" *ngIf="sportTypeSelected > -1" style="margin:1% 0 0 4%; width:100%; float:left">
                <div style="float:left; width: 20%" *ngFor="let item of sportDataList.reverse()">
                  <span style="width: 100%;margin: 0 auto">
                    <img style="margin: 0 auto; border-radius: 20%;border: solid 1px; cursor:pointer"
                         [ngStyle]="{
                      'background': defaults.sport_id === item.sport_id ? '#e91e63' : '#e6e6e6',
                      'border': defaults.sport_id === item.sport_id ? '#fff' : '#808080'
                    }"
                         [src]="item.sport_id !== defaults.sport_id ? item.icon_unselected : item.icon_selected" (click)="selectSport(item)">
                  </span>
                  <p style="text-align: center;">{{ item.name }}</p>
                </div>
              </div>
            </div>

            <div style="margin: 2% 0 0 2%;width: 48%;float: left;" *ngIf="defaults.sport_id">

              <div style="width: 100%;float:left" >
                <mat-card class="custom-card" [class.selected-collectif]="defaults.course_type === 1" (click)="setCourseType('collectif', 1)" >
                  <mat-card-content>
                    <p>{{'course_colective' |translate }}</p>
                  </mat-card-content>
                </mat-card>
                <mat-checkbox [checked]="false" style="margin: 10px 0 0 0;float: left;"[disabled]="defaults.course_type === null || defaults.course_type !== 1" (click)="setFlexibility($event)">
                  {{'flexidates' | translate}}

                </mat-checkbox>
                <div style="float: left; margin: 17px">
                  <mat-icon svgIcon="mat:info"></mat-icon>
                </div>
              </div>

              <div style="width: 100%;float:left">
                <mat-card class="custom-card" [class.selected-prive]="defaults.course_type === 2" (click)="setCourseType('privee', 2)">
                  <mat-card-content>
                    <p>{{'course_private' |translate }}</p>
                  </mat-card-content>
                </mat-card>
                <mat-checkbox [checked]="false" style="margin: 10px 0 0 0;float: left;" [disabled]="defaults.course_type === null || defaults.course_type !== 2" (click)="setFlexibility($event)">
                  {{'flexidates' | translate}}
                </mat-checkbox>
                <div style="float: left; margin: 17px">
                  <mat-icon svgIcon="mat:info"></mat-icon>
                </div>
              </div>
              <div style="width: 100%;float:left">
                <mat-card class="custom-card" [class.selected-activity]="defaults.course_type === 3" (click)="setCourseType('activity', 3)">
                  <mat-card-content>
                    <p>{{'activity' |translate }}</p>
                  </mat-card-content>
                </mat-card>
                <div style="float: left; margin: 17px">
                  <mat-icon svgIcon="mat:info"></mat-icon>
                </div>
              </div>
            </div>


          </div>
          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseTypeFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button [disabled]="courseTypeFormGroup.invalid" color="primary" mat-raised-button matStepperNext>
              {{'next' | translate}}
            </button>
          </div>
        </mat-step>

        <mat-step [stepControl]="courseInfoFormGroup">
          <ng-template matStepLabel>{{'general_data' | translate}}</ng-template>

          <div class="px-6 py-4 flex flex-col" [formGroup]="courseInfoFormGroup">

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 50px 0 0 0;">

              <mat-tab-group  style="margin-top:-50px" [(selectedIndex)]="selectedTabNameIndex" (selectedTabChange)="onTabNameChanged($event);">
                <mat-tab label="FR">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 0">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name" matInput required type="text" [(ngModel)]="defaults.translations.fr.name">
                      <mat-error *ngIf="courseInfoFormGroup.controls?.course_name?.errors?.['required']">
                        {{'courses.errors.coursename' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="EN">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 1">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_en" matInput required type="text" [(ngModel)]="defaults.translations.en.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="DE">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 2">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_de" matInput required type="text" [(ngModel)]="defaults.translations.de.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="ES">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 3">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_es" matInput required type="text" [(ngModel)]="defaults.translations.es.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
                <mat-tab label="IT">
                  <div class="flex-auto" style="width: 100%" *ngIf="selectedTabNameIndex === 4">
                    <label for="courseName">{{'courses.coursename' | translate}} <span style="color:red">(*)</span></label>
                    <mat-form-field appearance="outline" style="width: 100%">
                      <input id="courseName" formControlName="course_name_it" matInput required type="text" [(ngModel)]="defaults.translations.it.name">
                    </mat-form-field>
                  </div>

                </mat-tab>
              </mat-tab-group>

              <div class="flex-auto" style="width: 15%">
                <label for="courseName">{{(defaults.is_flexible ? 'min_price' : 'price' )|translate}} (CHF) <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input id="courseName" formControlName="price" matInput [required]="!defaults.is_flexible" [type]="defaults.is_flexible ? 'text' : 'number'" [(ngModel)]="defaults.price" [readonly]="defaults.is_flexible"
                         [value]="defaults.is_flexible ? 'FLEXIBLE' : null">
                  <mat-error *ngIf="courseInfoFormGroup.controls.price.errors?.['required'] && !defaults.is_flexible">
                    {{'courses.errors.price' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="flex-auto" style="width: 30%">
                <label for="station">{{'station' | translate}}<span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input type="text" id="station" placeholder="{{'choose_station' | translate}}" matInput [formControl]="myControlStations" [matAutocomplete]="autoStation" [(ngModel)]="defaults.station_id">
                  <mat-autocomplete #autoStation="matAutocomplete" [displayWith]="displayFnStation">
                    <mat-option *ngFor="let option of filteredStations | async" [value]="option" (onSelectionChange)="setStation(option)">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>

                </mat-form-field>
                <mat-error *ngIf="myControlStations.touched && myControlStations.value === null">
                  {{'choose_station' | translate}}
                </mat-error>
              </div>

            </div>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

              <div class="flex-auto" style="width: 60%;">

                <div style="float:left; width: 100%">
                  <div class="image-container" style="float: left;width: 50%;">

                    <img [src]="defaults.image !== null && defaults.image !== '' ? defaults.image : '../../../../assets/img/no-image.jpg'"
                         alt="Image Preview" width="400" height="290" style="width: 400px;height: 290px;object-fit: contain;margin:0 auto">
                  </div>

                  <div class="input-container">

                    <input type="file" formControlName="image" placeholder="Select an image" (change)="onFileChanged($event)" id="file"/>
                    <label for="file" class="btn-1"><mat-icon svgIcon="mat:cloud_upload"></mat-icon></label>
                    <p style="font-size: 12px">{{'image_max' | translate}}</p>
                    <p style="font-size: 12px">{{'image_format' | translate}}</p>
                    <p style="font-size: 12px">{{'image_recomendation' | translate}}</p>
                  </div>
                </div>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

              <div class="flex-auto" style="width: 40%">
                <label for="ageFrom">{{'courses.min_age' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input id="ageFrom" type="number" placeholder="{{'courses.min_age' | translate}}" formControlName="ageFrom" aria-label="Number" matInput [(ngModel)]="defaults.age_min"/>
                  <mat-error *ngIf="courseInfoFormGroup?.controls?.ageFrom?.errors?.['required']">
                    {{'courses.errors.min_age' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="flex-auto" style="width: 40%">
                <label for="ageTo">{{'courses.max_age' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <input id="ageTo" type="number" placeholder="{{'courses.max_age' | translate}}" formControlName="ageTo" aria-label="Number" matInput [(ngModel)]="defaults.age_max"/>
                  <mat-error *ngIf="courseInfoFormGroup?.controls?.ageTo?.errors?.['required']">
                    {{'courses.errors.max_age' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin-top: 3%">

              <mat-tab-group style="width: 100%" [(selectedIndex)]="selectedTabDescIndex" (selectedTabChange)="onTabDesChanged($event);" preserveContent>
                <mat-tab label="FR">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 0">
                    <label for="summary_fr" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_fr" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.fr.short_description" formControlName="summary"></angular-editor>
                    <mat-error *ngIf="courseInfoFormGroup?.controls?.summary?.errors?.['required']">
                      {{'courses.errors.summary' | translate}}
                    </mat-error>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 0">
                    <label for="description_fr" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_fr" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.fr.description" formControlName="description"></angular-editor>
                    <mat-error *ngIf="courseInfoFormGroup?.controls?.description?.errors?.['required']">
                      {{'courses.errors.desc' | translate}}
                    </mat-error>
                  </div>
                </mat-tab>
                <mat-tab label="EN">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 1">
                    <label for="summary_en" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_en" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.en.short_description" formControlName="summary_en"></angular-editor>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 1">
                    <label for="description_en" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_en" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.en.description" formControlName="description_en"></angular-editor>
                  </div>
                </mat-tab>
                <mat-tab label="DE">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 2">
                    <label for="summary_de" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_de" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.de.short_description" formControlName="summary_de"></angular-editor>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 2">
                    <label for="description_de" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_de" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.de.description" formControlName="description_de"></angular-editor>
                  </div>
                </mat-tab>
                <mat-tab label="ES">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 3">
                    <label for="summary_es" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_es" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.es.short_description" formControlName="summary_es"></angular-editor>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 3">
                    <label for="description_es" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_es" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.es.description" formControlName="description_es"></angular-editor>
                  </div>
                </mat-tab>
                <mat-tab label="IT">
                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 4">
                    <label for="summary_it" style="font-size: 18px">{{'courses.summary' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="summary_it" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.it.short_description" formControlName="summary_it"></angular-editor>
                  </div>

                  <div class="flex-auto" style="width: 100%" *ngIf="isAngularHtmlEditing || selectedTabDescIndex === 4">
                    <label for="description_it" style="font-size: 18px">{{'courses.desc' | translate}} <span style="color:red">(*)</span></label>
                    <angular-editor id="description_it" placeholder="{{'enter_text' | translate}}" [(ngModel)]="defaults.translations.it.description" formControlName="description_it"></angular-editor>
                  </div>
                </mat-tab>
              </mat-tab-group>

            </div>
          </div>
          <div class="actions flex items-center justify-between gap-2" style="width: 100%">
            <!-- Botón de traducción a la izquierda -->
            <button mat-raised-button color="primary" (click)="translateCurrentTabToOthers()">
              {{ 'translate_ia' | translate }}
            </button>
            <!-- Contenedor para alinear los botones restantes a la derecha -->
            <div class="flex items-center gap-2">
              <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">
                Reset
              </button>
              <button color="primary" (click)="checkStep2PrivateNoFlex(stepper)" mat-raised-button>
                {{'next' | translate}}
              </button>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="courseInfoPriveFormGroup" [completed]="false" [editable]="true">
          <ng-template matStepLabel>{{'course_info' | translate}}</ng-template>

          <mat-spinner *ngIf="loadingTable" style="margin: 0 auto;"></mat-spinner>
          <div class="px-6 py-4 flex flex-col" [formGroup]="courseInfoPriveFormGroup" *ngIf="!loadingTable">

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <div class="flex-auto" style="width: 50%;">

                <label for="dateFromPrivate" *ngIf="defaults.is_flexible">{{'courses.date_reservable' | translate}} <span style="color:red">(*)</span></label>
                <label for="dateFromPrivate" *ngIf="!defaults.is_flexible">{{'from' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateFromPrivate" [matDatepicker]="pickerFrom" [matDatepickerFilter]="myHolidayFilter" formControlName="fromDate" [min]="mode !== 'update' ? minDate : ''" [max]="maxDate" [(ngModel)]="defaults.date_start_res">
                  <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFrom [startAt]="minDate"></mat-datepicker>

                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.fromDate.errors?.['required']">
                    <span *ngIf="defaults.is_flexible">
                      {{'courses.errors.date_reservable' | translate}}
                    </span>
                    <span *ngIf="!defaults.is_flexible">
                      {{'courses.errors.from' | translate}}
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex-auto" style="width: 50%;">

                <label for="dateToPrivate" *ngIf="defaults.is_flexible">{{'courses.date_reservable_to' | translate}} <span style="color:red">(*)</span></label>
                <label for="dateToPrivate" *ngIf="!defaults.is_flexible">{{'to' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateToPrivate" [matDatepicker]="pickerTo" formControlName="toDate" [matDatepickerFilter]="myHolidayFilter" [min]="courseInfoPriveFormGroup.value.fromDate" [max]="maxDate"
                         [(ngModel)]="defaults.date_end_res"
                         [disabled]="courseInfoPriveFormGroup.value.fromDate === null">
                  <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo [startAt]="courseInfoPriveFormGroup.value.fromDate"></mat-datepicker>

                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.toDate.errors?.['required']">
                    <span *ngIf="defaults.is_flexible">
                      {{'courses.errors.date_reservable_to' | translate}}
                    </span>
                    <span *ngIf="!defaults.is_flexible">
                      {{'courses.errors.to' | translate}}
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
              <div class="flex-auto" style="width: 50%;">

                <label for="durationPrivate">{{'courses.dur_min' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="durationPrivate" formControlName="duration" placeholder="{{'courses.dur_min' | translate}}" matInput [matAutocomplete]="autoTimesPrivate" [(ngModel)]="defaults.duration">
                  <mat-autocomplete #autoTimesPrivate="matAutocomplete">
                    <mat-option *ngFor="let duration of durations" [value]="duration.text">
                      {{duration.text}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="flex-auto" style="width: 50%;">

                <label for="participants">{{'max_pax' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <mat-label>{{'max_pax' | translate}}</mat-label>
                  <input id="participants" formControlName="participants" matInput required type="number" [(ngModel)]="defaults.max_participants">
                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.participants.errors?.['required']">
                    {{'courses.errors.max_pax' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" *ngIf="!defaults.is_flexible">
              <div class="flex-auto">

                <label for="fromHour">{{'start_hour' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="fromHour" formControlName="fromHour" placeholder="{{'start_hour' | translate}}" matInput [matAutocomplete]="autoHourFrom" [(ngModel)]="defaults.hour_min">
                  <mat-autocomplete #autoHourFrom="matAutocomplete">
                    <mat-option *ngFor="let hour of hours" [value]="hour">
                      {{hour}}
                    </mat-option>
                  </mat-autocomplete>

                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.fromHour.errors?.['required']">
                    {{'courses.errors.start_hour' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex-auto">

                <label for="maxDuration">{{'end_hour' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="toHour" formControlName="maxDuration" placeholder="{{'end_hour' | translate}}" matInput [matAutocomplete]="autoHourTo" [(ngModel)]="defaults.hour_max">
                  <mat-autocomplete #autoHourTo="matAutocomplete">
                    <mat-option *ngFor="let hour of filteredToHours" [value]="hour">
                      {{hour}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="courseInfoPriveFormGroup.controls.maxDuration.errors?.['required']">
                    {{'courses.errors.end_hour' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" *ngIf="defaults.is_flexible">
              <div class="flex-auto">

                <label for="durationPrivate" style="font-size: 20px;">{{'courses.when' | translate}}</label>
              </div>
              <div class="flex-auto">

                <mat-checkbox (change)="onCheckboxChange('unique')" [checked]="periodeUnique" formControlName="periodeUnique">{{'courses.uniperiod' | translate}}</mat-checkbox>
              </div>
              <div class="flex-auto">

                <mat-checkbox (change)="onCheckboxChange('multiple')" [checked]="periodeMultiple" formControlName="periodeMultiple">{{'courses.multiperiod' | translate}}</mat-checkbox>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" *ngIf="defaults.course_type === 3 && !periodeMultiple && defaults.is_flexible">
              <div class="flex-auto" style="width:50%">

                <label for="dateFromPrivate" *ngIf="defaults.is_flexible">{{'from' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateFromPrivate" [matDatepicker]="pickerFromUnique" formControlName="fromDateUnique" [matDatepickerFilter]="myHolidayFilter" [min]="mode !== 'update' ? minDate : ''"
                         [max]="maxDate" [(ngModel)]="defaults.date_start">
                  <mat-datepicker-toggle matSuffix [for]="pickerFromUnique"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFromUnique [startAt]="minDate"></mat-datepicker>

                  <mat-error *ngIf="courseInfoPriveFormGroup?.controls?.fromDateUnique?.errors?.['required']">
                    {{'courses.errors.from' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex-auto" style="width:50%">

                <label for="dateToPrivate" *ngIf="defaults.is_flexible">{{'to' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" style="width: 100%">

                  <input matInput id="dateToPrivate" [matDatepicker]="pickerToUnique" formControlName="toDateUnique" [matDatepickerFilter]="myHolidayFilter" [min]="courseInfoPriveFormGroup?.value?.fromDateUnique" [max]="maxDate" [(ngModel)]="defaults.date_end"
                         [disabled]="courseInfoPriveFormGroup.value.fromDate === null">
                  <mat-datepicker-toggle matSuffix [for]="pickerToUnique"></mat-datepicker-toggle>
                  <mat-datepicker #pickerToUnique [startAt]="courseInfoPriveFormGroup?.value?.fromDateUnique"></mat-datepicker>

                  <mat-error *ngIf="courseInfoPriveFormGroup?.controls?.toDateUnique?.errors?.['required']">
                    {{'courses.errors.to' | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="sm:flex-row gap-2 sm:gap-6" style="margin: 5% 0 0 0" *ngIf="defaults.course_type === 3 && periodeMultiple && defaults.is_flexible">
              <div style="width: 100%">

                <table #activityDatesTable id="activity-dates-table" mat-table [dataSource]="dataSourceDatePrivate" matSort style="border: 1px solid #e6e6e6">

                  <ng-container matColumnDef="dateFrom">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'courses.date_start' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> <span [ngStyle]="{'text-decoration': mode === 'update' && (element.active === 0 || !element.active) ? 'line-through' : ''}">{{element.dateFrom}}</span> </td>
                  </ng-container>

                  <ng-container matColumnDef="dateTo">
                    <th mat-header-cell *matHeaderCellDef> {{'courses.date_end' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> <span [ngStyle]="{'text-decoration': mode === 'update' && (element.active === 0 || !element.active) ? 'line-through' : ''}">{{element.dateTo}}</span> </td>
                  </ng-container>

                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element; let idx = index">
                      <mat-icon svgIcon="mat:delete" style="cursor:pointer" *ngIf="(element.active || element.active === 1) || !element.id" (click)="removePrivateDate(idx, element.main, element.period)"></mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedPrivateDateColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedPrivateDateColumns;let i = index"
                      [hidden]="!row.main && row?.period !== selectedPeriod" (click)="selectPeriod(row.mainPeriod, row.main)" [style.cursor]="row.main ? 'pointer': 'default'"></tr>
                </table>
              </div>
              <div style="width: 100%; margin: 2% 0 0 0; float:left;border: 1px solid #e6e6e6;padding: 1%;">
                <div style="width: 5%; float:left;">

                  <button class="add-date" style="cursor:pointer" mat-fab (click)="openDialogPrivateDate()">
                    <mat-icon svgIcon="mat:add" class="icon-date"></mat-icon>
                  </button>
                </div>
                <div style="width: 95%; float:left;margin: 1% 0 0 0;">
                  <p> {{'add_date' | translate }}</p>
                </div>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" style="margin: 3% 0 0 0" >

              <div class="flex-auto">

                <label for="all-days">{{'days' | translate}}</label>
                <div style="width: 100%">

                  <mat-checkbox id="all-days" [checked]="areAllTrue(defaults?.settings?.weekDays)" (change)="addWeekDay($event, 'all')">Touts selectionner</mat-checkbox>
                </div>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" >

              <div class="flex-auto" style="padding-left: 5%">

                <div style="width: 100%">
                  <ul style="float: left;width: 25%;">
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.monday" (change)="addWeekDay($event, 'monday')">{{'Lundi' | translate}}</mat-checkbox></li>
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.tuesday" (change)="addWeekDay($event, 'tuesday')">{{'Mardi' | translate}}</mat-checkbox></li>
                  </ul>
                  <ul style="float: left;width: 25%;">
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.wednesday" (change)="addWeekDay($event, 'wednesday')">{{'Mercedi' | translate}}</mat-checkbox></li>
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.thursday" (change)="addWeekDay($event, 'thursday')">{{'Jeudi' | translate}}</mat-checkbox></li>
                  </ul>
                  <ul style="float: left;width: 25%;">
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.friday" (change)="addWeekDay($event, 'friday')">{{'Vendredi' | translate}}</mat-checkbox></li>
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.saturday" (change)="addWeekDay($event, 'saturday')">{{'Samedi' | translate}}</mat-checkbox></li>
                  </ul>
                  <ul style="float: left;width: 25%;">
                    <li><mat-checkbox [checked]="defaults?.settings?.weekDays.sunday" (change)="addWeekDay($event, 'sunday')">{{'Diamanche' | translate}}</mat-checkbox></li>

                  </ul>
                </div>
              </div>

            </div>

            <div class="flex flex-col sm:flex-row gap-2 sm:gap-6" *ngIf="defaults.is_flexible">
              <div class="flex-auto">

                <label for="fromHour">{{'start_hour' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="fromHour" formControlName="fromHour" placeholder="{{'start_hour' | translate}}" matInput [matAutocomplete]="autoHourFrom" [(ngModel)]="defaults.hour_min">
                  <mat-autocomplete #autoHourFrom="matAutocomplete">
                    <mat-option *ngFor="let hour of hours" [value]="hour">
                      {{hour}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="flex-auto">

                <label for="maxDuration">{{'end_hour' | translate}} <span style="color:red">(*)</span></label>
                <mat-form-field appearance="outline" class="flex-auto" style="width: 100%">
                  <input type="text" id="maxDuration" formControlName="toHour" placeholder="{{'end_hour' | translate}}" matInput [matAutocomplete]="autoHourTo" [(ngModel)]="defaults.hour_max">
                  <mat-autocomplete #autoHourTo="matAutocomplete">
                    <mat-option *ngFor="let hour of filteredToHours" [value]="hour">
                      {{hour}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div style="width: 100%; margin: 2% 0 1% 0; float:left;border: 1px solid #e6e6e6;padding: 1%;" *ngIf="this.defaults.options">
              <div style="width: 5%; float:left;">

                <button class="add-date" style="cursor:pointer" mat-fab (click)="addGroup()">
                  <mat-icon svgIcon="mat:add" class="icon-date"></mat-icon>
                </button>
              </div>
              <div style="width: 95%; float:left;margin: 1% 0 0 0;">
                <p> {{'add_group' | translate }}</p>
              </div>
            </div>




          </div>
          <div class="px-6 py-4 flex flex-col" *ngIf="this.defaults.options">
            <ng-container *ngFor="let group of groups; let i = index">
              <div class="flex flex-wrap gap-4 mb-4">
                <div class="flex-auto" style="flex-basis: 20%;">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{'group_name' | translate}}</mat-label>
                    <input type="text" matInput [(ngModel)]="groups[i].groupName" id="groupName{{i}}" name="groupName{{i}}"/>
                  </mat-form-field>
                </div>
                <div class="flex-auto" style="flex-basis: 15%;">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{'age_min' | translate}}</mat-label>
                    <input type="number" placeholder="Min." aria-label="Number" matInput  id="ageMin{{i}}"
                           [min]="0" [(ngModel)]="groups[i].ageMin" name="ageMin{{i}}"/>
                  </mat-form-field>
                </div>
                <div class="flex-auto" style="flex-basis: 15%;">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{'age_max' | translate}}</mat-label>
                    <input type="number" placeholder="Max" aria-label="Number" matInput
                           [min]="group.ageMin ?? 0" [(ngModel)]="groups[i].ageMax" id="ageMax{{i}}" name="ageMax{{i}}"/>
                  </mat-form-field>
                </div>
                <div class="flex-auto" style="flex-basis: 20%;">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{'option_name' | translate}}</mat-label>
                    <input type="text" matInput [(ngModel)]="groups[i].optionName" id="optionName{{i}}" name="optionName{{i}}"/>
                  </mat-form-field>
                </div>
                <div class="flex-auto" style="flex-basis: 15%;">
                  <mat-form-field appearance="outline" class="w-full">
                    <mat-label>{{'option_price' | translate}}</mat-label>
                    <input type="number" placeholder="Price" aria-label="Number"
                           matInput [min]="1" [(ngModel)]="groups[i].price" id="price{{i}}" name="price{{i}}"/>
                  </mat-form-field>
                </div>
                <div class="flex-auto" style="flex-basis: 10%; display: flex; align-items: center;">
                  <button mat-icon-button (click)="removeGroup(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button color="primary" mat-raised-button *ngIf="defaults.is_flexible" (click)="checkStep3PrivateFlex(stepper)">
              {{'next' | translate}}
            </button>
            <button color="primary" mat-raised-button matStepperNext (click)="save()" *ngIf="!defaults.is_flexible">
              {{'save' | translate}}
            </button>
          </div>
        </mat-step>
        <mat-step *ngIf="defaults.course_type === 3 && this.defaults.is_flexible">

          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button color="primary" mat-raised-button (click)="save()">
              {{'save' | translate}}
            </button>
          </div>
          <ng-template matStepLabel>{{'prices' | translate}}</ng-template>
          <div class="p-6">

            <mat-form-field appearance="outline" class="flex-auto">
              <input matInput placeholder="Enter people number" type="number" [value]="people" [(ngModel)]="people" (ngModelChange)="updateTable(people, false)">
            </mat-form-field>

            <div class="table-container" >
              <table mat-table [dataSource]="dataSourceFlexiblePrices" class="mat-elevation-z8" #scrollContainer>
                <caption>{{'price' | translate}} {{'in' | translate}} CFH</caption>

                &lt;!&ndash; Intervalo Column &ndash;&gt;
                <ng-container matColumnDef="intervalo">
                  <th mat-header-cell *matHeaderCellDef> {{'interval' | translate}} </th>
                  <td mat-cell *matCellDef="let row"> {{row.intervalo}} </td>
                </ng-container>

                &lt;!&ndash; Personas Columns &ndash;&gt;
                <ng-container *ngFor="let col of displayedColumnsFlexiblePrices.slice(1)" [matColumnDef]="col">
                  <th mat-header-cell *matHeaderCellDef> {{col}} </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-form-field appearance="outline" class="flex-auto">
                      <input class="flex-auto" matInput [(ngModel)]="row[col]" style="margin-top:3%">
                    </mat-form-field>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsFlexiblePrices"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsFlexiblePrices;"></tr>
              </table>
            </div>
          </div>

          <div class="actions flex items-center justify-end gap-2" style="width: 100%">
            <button (click)="stepper.reset()" [disabled]="courseInfoPriveFormGroup.pristine" color="primary" mat-button type="button">Reset</button>
            <button color="primary" mat-raised-button (click)="save()">
              {{'save' | translate}}
            </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>

    </div>
  </div>

</div>
