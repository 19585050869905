<form >
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{'bookings_page.bonus.title' | translate }}</h2>

    <!--<button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>-->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">

    <div style="width:100%">
      <mat-radio-group>
        <ng-container *ngFor="let b of bonuses">
        <mat-radio-button *ngIf="!b.payed " [disabled]="isInUse(b.id)" class="radio"  (change)="bonus = b" [value]="b">
          {{ b.code }} - {{'remaining_balance' | translate}}: {{ b.remaining_balance }} {{defaults.currency}} <span style="color:#8fd14f">{{ isInUse(b.id) ? 'applied' : '' | translate}}</span>
        </mat-radio-button>
      </ng-container>
      </mat-radio-group>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button color="primary" mat-flat-button (click)="closeModal()">{{'confirm' | translate}}</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="false">{{'no' | translate}}</button>
  </mat-dialog-actions>
</form>
