<form >
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{defaults.title}}</h2>

    <!--<button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>-->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <h1>{{defaults.message}}</h1>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button color="primary" mat-flat-button [mat-dialog-close]="true">{{'confirm' | translate}}</button>
    <button color="primary" mat-flat-button [mat-dialog-close]="false">{{'no' | translate}}</button>
  </mat-dialog-actions>
</form>
